import { LngLatLike, LngLatBoundsLike } from 'maplibre-gl';

export interface VigilMapUser {
  name: string
}

export interface VigilMapDeviceHistoryItem {
  lat: number
  lng: number
  acc: number
}

export interface VigilMapDevice {
  uuid: string
  chargeLevel?: number
  chargeState?: 'Charging' | 'Discharging'
  lastSeen?: Date
  lat: number
  lng: number
  user?: VigilMapUser
  // TODO: Remove this maybe? We can just use the heatmap instead
  history?: VigilMapDeviceHistoryItem[]
}

export interface VigilMapBeacon {
  uuid: string
  name: string
  lat: number
  lng: number
  color?: 'red' | 'green' | 'blue' | 'yellow' | 'orange' | 'purple'
  additionalInfo?: React.ReactNode
}

export enum VigilMapThemeId {
  BASIC = 'BASIC',
  DARK_MATTER = 'DARK_MATTER',
  SATELLITE = 'SATELLITE',
}

export interface VigilMapAgentInfo {
  lat: number
  lng: number
}

export interface HeatmapPoint {
  lat: number;
  lng: number;
  intensity?: number;
}

export interface VigilMapContextMenuItem {
  label: string
  onClick: () => void
}

export interface VigilMapProps {
  sourceURL?: string
  theme?: VigilMapThemeId
  beacons?: VigilMapBeacon[]
  devices?: VigilMapDevice[]
  users?: VigilMapUser[]
  center?: LngLatLike
  zoom?: number
  maxBounds?: LngLatBoundsLike
  zoomControl?: boolean
  agentInfo?: VigilMapAgentInfo | null
  heatmapData?: HeatmapPoint[]
  keyboard?: {

  }
  mouse?: {
    pan?: boolean,
    zoom?: boolean
  }
  touch?: {
    pan?: boolean,
    zoom?: boolean
    rotate?: boolean
    pitch?: boolean
  }
  contextMenu?: {
    enabled: boolean
    items: VigilMapContextMenuItem[]
  }
  clickMenu?: {
    enabled: boolean
  }
}
