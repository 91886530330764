import { FC, useContext } from 'react';
import { IProtocolInstanceSelect, IDirectoryProtocolInstanceLog } from 'vigil-datamodel';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useTableData } from '../../hooks/use_table_data';
import { Table } from '../../components/table/table';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { TTuuid } from 'tt-uuid';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';

interface ScreenHomeProtocolInstanceLogsProps { }

export const ScreenHomeProtocolInstanceLogs: FC<ScreenHomeProtocolInstanceLogsProps> = (props) => {
  const { protocolInstanceParent, fetchProtocolInstanceParent } = useOutletContext<{
    protocolInstanceParent: IProtocolInstanceSelect,
    fetchProtocolInstanceParent: () => void
  }>();

  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const { data: protocolInstanceLogs, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryProtocolInstanceLog>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryProtocolInstanceLogs({
        uuidOrganization: organization.data.uuid,
        filters: [...filters, { id: 'uuidProtocolInstance', query: 'equals', value: protocolInstanceParent.uuid }],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  // TODO: Add later, so that we can see more details about the logs
  // function navigateProtocolInstance(selectedProtocolInstance: IDirectoryProtocolInstanceLog) {
  //   navigate(ROUTES.ROUTE_HOME_PROTOCOL_INSTANCE.replace(':uuid', protocolParent.uuid).replace(':instance_uuid', selectedProtocolInstance.uuid))
  // }

  return (
    <div className='flex flex-col h-full'>
      <Table<IDirectoryProtocolInstanceLog>
        className='h-full flex flex-col drop-shadow my-2'
        name='Protocol Instances'
        data={protocolInstanceLogs}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'description',
            header: 'Description',
            sortable: false,
            value(item) {
              return <div>{item.description}</div>;
            },
          },
          // TODO: Add a new screen that shows more details on a specific log
          // {
          //   id: 'data',
          //   header: 'Data',
          //   sortable: false,
          //   value(item) {
          //     return <div>{JSON.stringify(item.data)}</div>;
          //   },
          // },
          {
            id: 'tags',
            header: 'Tags',
            sortable: false,
            value(item) {
              return <div>{item.tags.join(', ')}</div>;
            },
          },
          {
            id: 'creator',
            header: 'Creator',
            sortable: false,
            value(item) {
              if (item.creator.type === 'server') return <div>Server</div>;
              return <div>Device UUID: {item.creator.uuidDevice}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}

        filtersOptions={[
          new TextFilter('description', 'Description', 'contains'),
          new TextFilter('tags', 'Tags', 'contains'),
          new TextFilter('creator.uuidDevice', 'Creator Device UUID', 'contains'),
          new TextFilter('creator.type', 'Creator Type', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
}; 