import React, { useContext, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IDirectoryUser, RUser, RUserInvite } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/use_modal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { ModalUserInviteCreate } from './func_user_invite/modal_user_invite_create';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { useTableData } from '../../hooks/use_table_data';
import { ModalDeleteMany } from './modal_delete_many';


interface ScreenHomeUsersAcceptProps { }

export const ScreenHomeUsersAccept: React.FC<ScreenHomeUsersAcceptProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const { isOpen: isOpenModalUserInviteCreate, toggle: toggleModalUserInviteCreate } = useModal();
  const { isOpen: isOpenModalUserDelete, toggle: toggleModalUserDelete } = useModal();

  const [selectedUsersState, setSelectedUsers] = useState<IDirectoryUser[]>([]);

  const { data: users, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryUser>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryUsers({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateUser(selectedUser: IDirectoryUser) {
    navigate(ROUTES.ROUTE_HOME_USER.replace(':uuid', selectedUser.uuid))
  }

  function deleteUsers(selectedUsers: IDirectoryUser[]) {
    setSelectedUsers(selectedUsers)
    toggleModalUserDelete();
  }

  async function deleteUsersOnServer(userUuids: string[]) {
    if (!organization.data) return;
    await vigil.functions.deleteUsers({
      uuidOrganization: organization.data.uuid,
      uuids: userUuids
    })
  }

  return (
    <div className='pt-2 h-full w-full'>
      <ModalUserInviteCreate isOpen={isOpenModalUserInviteCreate} toggle={toggleModalUserInviteCreate} onSubmit={forceRefetch} />
      <ModalDeleteMany
        isOpen={isOpenModalUserDelete}
        toggle={toggleModalUserDelete}
        deleteCallback={async () => deleteUsersOnServer(selectedUsersState.map(user => user.uuid))}
        type='users'
        data={selectedUsersState.map(user => ({ uuid: user.uuid, label: user.firstName + ' ' + user.lastName }))}
        onSubmit={forceRefetch}
        extraComponents={<li>Remove the user-role links, but not the roles.</li>}
      />

      <Table<IDirectoryUser>
        className='h-full flex flex-col drop-shadow'
        name='Users'
        data={users}
        selectable={true}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'firstName',
            header: 'First name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateUser(item)}>
                  {item.firstName}
                </div>
              );
            },
          },
          {
            id: 'lastName',
            header: 'Last name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateUser(item)}>
                  {item.lastName}
                </div>
              );
            },
          },
          {
            id: 'roleCount',
            header: 'Roles Linked',
            sortable: true,
            value(item) {
              return <div>{item.roleCount}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={
          [
            new TextFilter('firstName', 'First Name', 'contains'),
            new TextFilter('lastName', 'Last Name', 'contains'),
            new DateFilter('uuid', 'Date Created', 'between'),
            new DateFilter('changeStamp', 'Last Updated', 'between'),
          ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}

        unSelectedActions={
          [
            {
              buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />invite user</div>,
              color: 'primary',
              disabled: !contextRoles.hasUserPermission(TTActions.C, RUserInvite),
              onClick: () => toggleModalUserInviteCreate(),
            }
          ]}
        selectedActions={
          [
            {
              buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
              color: 'error',
              disabled: !contextRoles.hasUserPermission(TTActions.D, RUser),
              onClick: (selectedItems) => deleteUsers(selectedItems)
            }
          ]}
        hoverActions={
          [
            {
              buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
              color: 'error',
              disabled: !contextRoles.hasUserPermission(TTActions.D, RUser),
              onClick: (selectedItems) => deleteUsers(selectedItems)
            }
          ]}
      />
    </div >
  );
};
