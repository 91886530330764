import { ICreator } from "./type_creator";
import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { protocolInstances } from "./bean_protocol_instance";
import { relations } from "drizzle-orm";

export const protocolInstanceLogs = sqliteTable('protocol_instance_logs', {
  ...schemaBase(),
  uuidProtocolInstance: text('uuid_protocol_instance').notNull().references(() => protocolInstances.uuid),
  creator: customJsonColumn<ICreator>('creator').notNull(),
  tags: customJsonColumn<string[]>('tags').notNull(),
  data: customJsonColumn<Record<string, any>>('data').notNull(),
  description: text('description').notNull(),
});

export const protocolInstanceLogsRelations = relations(protocolInstanceLogs, ({ one }) => ({
  protocolInstance: one(protocolInstances, {
    fields: [protocolInstanceLogs.uuidProtocolInstance],
    references: [protocolInstances.uuid],
  }),
}));
