import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { organizationRoles } from "./bean_organization_role";
import { users } from "./bean_user";
import { relations } from "drizzle-orm";

export const linkOrganizationRoleUser = sqliteTable('link_organization_role_user', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidOrganizationRole: text('uuid_organization_role').notNull().references(() => organizationRoles.uuid),
  uuidUser: text('uuid_user').notNull().references(() => users.uuid),
}, (table) => ({
  organizationRoleUserIdx: uniqueIndex('organization_role_user_idx').on(table.uuidOrganization, table.uuidOrganizationRole, table.uuidUser)
}));

export const linkOrganizationRoleUserRelations = relations(linkOrganizationRoleUser, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkOrganizationRoleUser.uuidOrganization],
    references: [organizations.uuid],
  }),
  role: one(organizationRoles, {
    fields: [linkOrganizationRoleUser.uuidOrganizationRole],
    references: [organizationRoles.uuid],
  }),
  user: one(users, {
    fields: [linkOrganizationRoleUser.uuidUser],
    references: [users.uuid],
  }),
}));