import { Device } from 'vigil-protocol';
import { InputTagSelect, TagOption } from '../../../../components/input_tag_select';

interface DevicesMembersProps {
  availableDevices: Device[];
  selectedDevices: Device[];
  onChange?: (devices: Device[]) => void;
}

export const DevicesMembers: React.FC<DevicesMembersProps> = ({
  availableDevices,
  selectedDevices,
  onChange,
}) => {
  // Convert devices to tag options
  const deviceOptions: TagOption[] = availableDevices.map(device => ({
    label: `${device.uuid}`,
    value: device.uuid,
  }));

  const selectedTags: TagOption[] = selectedDevices.map(device => ({
    label: `${device.uuid}`,
    value: device.uuid,
  }));

  const handleChange = (tags: TagOption[]): void => {
    // Convert selected tags back to Device objects
    const devices = tags.map(tag => {
      const device = availableDevices.find(d => d.uuid === tag.value);
      return device!;
    });
    onChange?.(devices);
  };

  return (
    <div>
      <div className="font-bold text-sm">Devices</div>
      <div className="italic text-sm">Select the devices that should be linked to this protocol (order matters)</div>
      <InputTagSelect
        options={deviceOptions}
        selectedTags={selectedTags}
        onChange={handleChange}
        errors={[]}
        labelText=""
      />
    </div>
  );
};
