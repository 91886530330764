import React, { useEffect } from 'react';
import { InputSelect } from '../../../../components/input_select';

interface Option {
  value: string | number | boolean;
  label: string;
}

export type ProtocolParameterTypeOption = {
  type: "option";
  options: Option[];
}

export interface ProtocolParameter {
  id: string;
  name: string;
  description: string;
  required: boolean;
  typing: ProtocolParameterTypeOption;
}

interface ProtocolParameterOptionInputProps {
  parameter: ProtocolParameter;
  value: string | number | boolean | undefined;
  onChange: (value: string | number | boolean) => void;
  onValidationErrors: (errors: string[]) => void;
}

export const ProtocolParameterOptionInput: React.FC<ProtocolParameterOptionInputProps> = ({ parameter, value, onChange, onValidationErrors }) => {
  const { required, name } = parameter;

  // Perform simple required validation
  useEffect(() => {
    if (required && (value === undefined || value === '')) {
      onValidationErrors([`${name} is required`]);
    } else {
      onValidationErrors([]);
    }
    // We intentionally exclude onValidationErrors from dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, required, name]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVal = e.target.value;
    // Determine the type based on the type of the first option
    const firstOption = parameter.typing.options[0];
    let parsedValue: string | number | boolean = selectedVal;

    if (typeof firstOption.value === 'number') {
      parsedValue = Number(selectedVal);
    } else if (typeof firstOption.value === 'boolean') {
      parsedValue = selectedVal === 'true';
    }

    onChange(parsedValue);
  };

  // Prepare options for the InputSelect, converting booleans to strings if necessary.
  const selectOptions = parameter.typing.options.map(opt => ({
    label: opt.label,
    value: typeof opt.value === 'boolean' ? String(opt.value) : opt.value,
  }));

  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm">{parameter.description}</div>
      <InputSelect
        value={value === undefined ? "" : String(value)}
        onChange={handleSelectChange}
        options={!required
          ? [{ label: 'Select an option', value: "" }, ...selectOptions]
          : selectOptions}
        errors={[]} labelText={''}
      />
    </div>
  );
};

export default ProtocolParameterOptionInput;