import { TTActions, TTPermissionSchema, TTPermissionSet, TTResource } from 'tt-permissions'
import { IOrganizationRoleSelect } from './beans_type';

/* Organization Resources  */
export class ROrganization extends TTResource { }
export class RUserInvite extends TTResource { }
export class RUser extends TTResource { }
export class RSite extends TTResource { }
export class RBeacon extends TTResource { }
export class RRole extends TTResource { }
export class RDevice extends TTResource { }
export class RBeaconSiteLink extends TTResource { }
export class RUserRoleLink extends TTResource { }
export class RProtocol extends TTResource { }
export class RBilling extends TTResource { }

export const schemaOrganization = new TTPermissionSchema([
  ROrganization,
  RUserInvite,
  RUser,
  RSite,
  RBeacon,
  RRole,
  RDevice,
  RBeaconSiteLink,
  RUserRoleLink,
  RProtocol,
  RBilling,
])

/* Organization Default Roles  */
export function getDefaultOwner() {
  const ps = new TTPermissionSet(schemaOrganization);
  ps.Grant(ROrganization, TTActions.CRUD);
  ps.Grant(RUserInvite, TTActions.CRUD);
  ps.Grant(RUser, TTActions.RD)
  ps.Grant(RSite, TTActions.CRUD);
  ps.Grant(RBeacon, TTActions.CRUD);
  ps.Grant(RRole, TTActions.CRUD);
  ps.Grant(RDevice, TTActions.CRUD);
  ps.Grant(RBeaconSiteLink, TTActions.CD);
  ps.Grant(RUserRoleLink, TTActions.CD);
  ps.Grant(RProtocol, TTActions.CRUD);
  ps.Grant(RBilling, TTActions.CRUD);
  return ps;
}

export function getDefaultAdmin() {
  const ps = new TTPermissionSet(schemaOrganization);
  ps.Grant(ROrganization, TTActions.CRU);
  ps.Grant(RUserInvite, TTActions.CRUD);
  ps.Grant(RUser, TTActions.RD)
  ps.Grant(RSite, TTActions.CRUD);
  ps.Grant(RBeacon, TTActions.CRUD);
  ps.Grant(RRole, TTActions.CRUD);
  ps.Grant(RDevice, TTActions.CRUD);
  ps.Grant(RBeaconSiteLink, TTActions.CD);
  ps.Grant(RUserRoleLink, TTActions.CD);
  ps.Grant(RProtocol, TTActions.CRUD);
  ps.Grant(RBilling, TTActions.R);
  return ps;
}

export function getDefaultReadOnly() {
  const ps = new TTPermissionSet(schemaOrganization);
  ps.Grant(RUserInvite, TTActions.R);
  ps.Grant(RUser, TTActions.R)
  ps.Grant(RSite, TTActions.R);
  ps.Grant(RBeacon, TTActions.R);
  ps.Grant(RRole, TTActions.R);
  ps.Grant(RDevice, TTActions.R);
  ps.Grant(RProtocol, TTActions.R);
  ps.Grant(RBilling, TTActions.R);
  return ps
}

export function getDefaultGuard() {
  const ps = new TTPermissionSet(schemaOrganization);
  ps.Grant(ROrganization, TTActions.R);
  ps.Grant(RSite, TTActions.R);
  ps.Grant(RBeacon, TTActions.R);
  ps.Grant(RDevice, TTActions.R);
  ps.Grant(RProtocol, TTActions.R);
  return ps;

}

/* Site Resources  */
export class RSiteName extends TTResource { }
export const schemaSite = new TTPermissionSchema([
  RSiteName,
])

export function hasUserPermission(roles: IOrganizationRoleSelect[], actions: TTActions, resource: typeof TTResource) {
  for (const role of roles) {
    const psRequestUser = new TTPermissionSet(schemaOrganization);
    psRequestUser.FromString(role.permissionString);
    if (psRequestUser.Can(actions, resource)) return true;
  }
  return false;
}

export function getLowestRole(roles: IOrganizationRoleSelect[]) {
  if (roles.length === 0) new Error('Provided roles array is empty');

  const createPoints = 4;
  const updatePoints = 3;
  const deletePoints = 2;
  const readPoints = 1;

  let lowestRolePoints = 9999;
  let lowestRole = roles[0];
  for (const role of roles) {
    let rolePoints = 0;
    const psRole = new TTPermissionSet(schemaOrganization);
    psRole.FromString(role.permissionString);
    for (const resource of schemaOrganization.GetAllResources()) {
      if (psRole.Can(TTActions.C, resource)) rolePoints += createPoints;
      if (psRole.Can(TTActions.U, resource)) rolePoints += updatePoints;
      if (psRole.Can(TTActions.D, resource)) rolePoints += deletePoints;
      if (psRole.Can(TTActions.R, resource)) rolePoints += readPoints;
    }
    if (rolePoints < lowestRolePoints) {
      lowestRolePoints = rolePoints;
      lowestRole = role;
    }
  }

  return lowestRole;
}

export function getHighestRole(roles: IOrganizationRoleSelect[]) {
  if (roles.length === 0) new Error('Provided roles array is empty');

  const createPoints = 4;
  const updatePoints = 3;
  const deletePoints = 2;
  const readPoints = 1;

  let highestRolePoints = -1;
  let highestRole = roles[0];
  for (const role of roles) {
    let rolePoints = 0;
    const psRole = new TTPermissionSet(schemaOrganization);
    psRole.FromString(role.permissionString);
    for (const resource of schemaOrganization.GetAllResources()) {
      if (psRole.Can(TTActions.C, resource)) rolePoints += createPoints;
      if (psRole.Can(TTActions.U, resource)) rolePoints += updatePoints;
      if (psRole.Can(TTActions.D, resource)) rolePoints += deletePoints;
      if (psRole.Can(TTActions.R, resource)) rolePoints += readPoints;
    }
    if (rolePoints > highestRolePoints) {
      highestRolePoints = rolePoints;
      highestRole = role;
    }
  }

  return highestRole;
}
