import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { Tabs } from '../../components/tabs';
import { IconUser, IconUserPlus } from '../../components/icons';

interface ScreenHomeUsersProps { }

export const ScreenHomeUsers: React.FC<ScreenHomeUsersProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  function navigateTab(id: string) {
    navigate(ROUTES.ROUTE_HOME_USERS + '/' + id)
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  return (
    <div className='p-4 w-full h-full flex flex-col'>
      <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
        {
          icon: IconUser,
          id: 'accept',
          text: 'Accepted Users'
        },
        {
          icon: IconUserPlus,
          id: 'invite',
          text: 'Pending Invites'
        }
      ]} />

      <div className='h-full'>
        <Outlet />
      </div>
    </div>
  );
};
