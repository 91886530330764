import { ReactElement, useContext, useState, createContext } from "react";
import { Navigate, useLocation, useParams, useSearchParams } from "react-router-dom";
import { ContextUser } from "../providers/provider_user";
import { ContextOrganization } from "../providers/provider_organization";
import { ROUTES } from "./routes";
import { ContextRoles } from "../providers/provider_roles";
import { TTActions } from "tt-permissions";
import ScreenUnauthorized from "../screens/unauthorized";
import { RBeacon, RRole, RSite, RUser } from "vigil-datamodel";

interface DirectorProps {
  children: ReactElement
}

let lockCount = 0;
interface DirectorContext {
  aquireLock: () => string;
  removeLock: (lockId: string) => void;
}
export const ContextDirector = createContext(null as unknown as DirectorContext);

export const Director: React.FC<DirectorProps> = (props: DirectorProps) => {
  /**
   * This component is responsible for handling route guards and 
   * redirecting to the appropriate places based on the application
   * state.
   */

  /* Dependancies */
  const user = useContext(ContextUser);
  const organization = useContext(ContextOrganization);
  const location = useLocation();
  const roles = useContext(ContextRoles);
  const params = useParams();
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();

  /* State */
  const [stateLocks, setLocks] = useState({} as Record<string, undefined>);

  /**
   * This method stops the director from operating, until the lock is released.
   * Useful if you want to make many changes to the data, but only want a redirect
   * at the end.
   * @returns String which is a lock ID
   */
  function aquireLock() {
    const lockId = (lockCount++).toString();
    setLocks({
      ...stateLocks,
      [lockId]: undefined,
    });
    return lockId;
  }

  /**
   * This method starts the directors operation again
   * @param lockId The lockID to remove
   */
  function removeLock(lockId: string) {
    setLocks(stateLocks => {
      const stateLocksUpdate = { ...stateLocks };
      delete stateLocksUpdate[lockId];
      return stateLocksUpdate
    });
  }

  // We always want to return the children with the context
  const component = (
    <ContextDirector.Provider value={{ aquireLock, removeLock }}>
      {props.children}
    </ContextDirector.Provider>
  );

  // If there are locks active, just return whatever was rendered.
  if (Object.keys(stateLocks).length > 0) {
    return component;
  }

  // ##################################
  // GUARDS
  // ##################################
  // Permissions check
  // USER READ PERMISSION
  if ((ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_USERS || ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_USER) && roles.hasUserPermission(TTActions.R, RUser) == false) {
    return <ScreenUnauthorized />
  }
  // SITE READ PERMISSION
  if ((ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_SITES || ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_SITE) && roles.hasUserPermission(TTActions.R, RSite) == false) {
    return <ScreenUnauthorized />
  }
  // BEACON READ PERMISSION
  if ((ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_BEACONS || ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_BEACON) && roles.hasUserPermission(TTActions.R, RBeacon) == false) {
    return <ScreenUnauthorized />
  }
  // ROLES READ PERMISSION
  if ((ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_ROLES || ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_ROLE) && roles.hasUserPermission(TTActions.R, RRole) == false) {
    return <ScreenUnauthorized />
  }

  // Public Routes
  if (location.pathname.startsWith('/public')) {
    return component;
  }

  // Identity - Guard
  if (!user.data || !user.data) {
    if (
      ROUTES.normalize(location.pathname) != ROUTES.ROUTE_TOOLBOX &&
      ROUTES.normalize(location.pathname) != ROUTES.ROUTE_SIGN_IN_REQUEST &&
      ROUTES.normalize(location.pathname) != ROUTES.ROUTE_SIGN_IN_CONFIRM
    ) {
      return <Navigate to={ROUTES.ROUTE_SIGN_IN_REQUEST} replace={true} />;
    }
    return component;
  }

  // User - Guard
  if (!user.data.firstName || !user.data.lastName) {
    if (ROUTES.normalize(location.pathname) != ROUTES.ROUTE_USER_CREATE) {
      return <Navigate to={ROUTES.ROUTE_USER_CREATE} replace={true} />;
    }
    return component;
  }
  if (!user.data.email) {
    if (
      ROUTES.normalize(location.pathname) != ROUTES.ROUTE_USER_CREATE_EMAIL_REQUEST &&
      ROUTES.normalize(location.pathname) != ROUTES.ROUTE_USER_CREATE_EMAIL_CONFIRM
    ) {
      return <Navigate to={ROUTES.ROUTE_USER_CREATE_EMAIL_REQUEST} replace={true} />;
    }
    return component;
  }
  if (!user.data.mobile) {
    if (
      ROUTES.normalize(location.pathname) != ROUTES.ROUTE_USER_CREATE_MOBILE_REQUEST &&
      ROUTES.normalize(location.pathname) != ROUTES.ROUTE_USER_CREATE_MOBILE_CONFIRM
    ) {
      return <Navigate to={ROUTES.ROUTE_USER_CREATE_MOBILE_REQUEST} replace={true} />;
    }
    return component;
  }

  // Organization - Guard
  if (!organization.data) {
    if (
      !ROUTES.matches(ROUTES.ROUTE_HOME_ONBOARDING + '/*', location.pathname) &&
      !ROUTES.matches(ROUTES.ROUTE_PROFILE + '/*', location.pathname)
    ) {
      return <Navigate to={ROUTES.ROUTE_HOME_ONBOARDING} replace={true} />;
    }
  }

  // ##################################
  // FORWARDS
  // ##################################
  if (ROUTES.normalize(location.pathname) == ROUTES.ROUTE_BASE) {
    return <Navigate to={ROUTES.ROUTE_HOME} replace={true} />;
  }
  if (ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME) {
    return <Navigate to={ROUTES.ROUTE_HOME_OVERVIEW} replace={true} />;
  }
  if (ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_OVERVIEW) {
    return <Navigate to={ROUTES.ROUTE_HOME_OVERVIEW_MAP} replace={true} />;
  }
  if (ROUTES.normalize(location.pathname) == ROUTES.ROUTE_HOME_USERS) {
    return <Navigate to={ROUTES.ROUTE_HOME_USERS_ACCEPT} replace={true} />;
  }

  // Forward identity
  if (user && user.data && (
    ROUTES.normalize(location.pathname) == ROUTES.ROUTE_SIGN_IN_REQUEST ||
    ROUTES.normalize(location.pathname) == ROUTES.ROUTE_SIGN_IN_CONFIRM
  )) {
    return <Navigate to={ROUTES.ROUTE_HOME} replace={true} />;
  }

  // Forward user
  if (user && ROUTES.normalize(location.pathname) == ROUTES.ROUTE_USER_CREATE) {
    return <Navigate to={ROUTES.ROUTE_HOME} replace={true} />;
  }

  // Forward profile basic info
  if (ROUTES.matches(ROUTES.ROUTE_PROFILE, location.pathname)) {
    return <Navigate to={ROUTES.ROUTE_PROFILE_BASIC_INFO} replace={true} />;
  }

  // Forward onboarding 
  if (ROUTES.matches(ROUTES.ROUTE_HOME_ONBOARDING, location.pathname) && organization.data) {
    return <Navigate to={ROUTES.ROUTE_HOME_OVERVIEW_MAP} replace={true} />;
  }

  // Forward admin
  // if (ROUTES.normalize(location.pathname) == ROUTES.ROUTE_ADMIN) {
  //   return <Navigate to={ROUTES.ROUTE_ADMIN_BEACONS} replace={true} />;
  // }

  // Forward protocols
  if (ROUTES.matches(ROUTES.ROUTE_HOME_PROTOCOL, location.pathname)) {
    return <Navigate to={{ pathname: ROUTES.ROUTE_HOME_PROTOCOL_OVERVIEW.replace(':uuid', params['uuid'] || ''), search: URLSearchParams.toString() }} replace={true} />;
  }

  // Forward protocol instances
  if (ROUTES.matches(ROUTES.ROUTE_HOME_PROTOCOL_INSTANCE, location.pathname)) {
    return <Navigate to={{ pathname: ROUTES.ROUTE_HOME_PROTOCOL_INSTANCE_OVERVIEW.replace(':uuid', params['uuid'] || '').replace(':instance_uuid', params['instance_uuid'] || ''), search: URLSearchParams.toString() }} replace={true} />;
  }

  // Forward device
  if (ROUTES.matches(ROUTES.ROUTE_HOME_DEVICE, location.pathname)) {
    return <Navigate to={{ pathname: ROUTES.ROUTE_HOME_DEVICE_HISTORY_STATISTICS.replace(':uuid', params['uuid'] || ''), search: URLSearchParams.toString() }} replace={true} />;
  }

  return component;
}
