import React from 'react';
import { InputBaseProps, InputBase } from './input_base';

export interface DurationState {
  hours?: number;
  minutes?: number;
}

interface InputDurationProps extends InputBaseProps {
  value: DurationState;
  minutesStep?: number;
  onChange?: (newTime: DurationState) => void;
}

export const InputDuration: React.FC<InputDurationProps> = (props) => {
  const minutesStep = props.minutesStep || 10;

  const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange) props.onChange({ ...props.value, hours: parseInt(event.target.value) });
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange) props.onChange({ ...props.value, minutes: parseInt(event.target.value) });
  };

  return (
    <InputBase {...props} >
      <div className={`p-2 inline-flex bg-base border-2 rounded-lg ${props.errors.length > 0 ? 'border-error' : 'border-base-300'}`}>
        <select className="bg-transparent text-lg" value={props.value.hours || 0} onChange={handleHourChange}>
          {[...Array(25)].map((_, i) => (
            <option key={i} value={i}>{`${i}`.padStart(2, '0')}</option>
          ))}
        </select>
        <span className="text-lg mx-1">:</span>
        <select className="bg-transparent text-lg" value={props.value.minutes || 0} onChange={handleMinuteChange}>
          {[...Array(60 / minutesStep)].map((_, i) => (
            <option key={i} value={i * minutesStep}>{`${i * minutesStep}`.padStart(2, '0')}</option>
          ))}
        </select>
      </div>
    </InputBase>
  );
};
