import { useEffect, useState } from 'react';
import { ProtocolParameter, ProtocolParameterTypeString } from 'vigil-protocol';
import { InputText } from '../../../../components/input_text';

type ProtocolParameterString = ProtocolParameter & { typing: ProtocolParameterTypeString };

interface ProtocolParameterStringInputProps {
  parameter: ProtocolParameterString;
  value: string;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: string) => void;
}

export const ProtocolParameterStringInput: React.FC<ProtocolParameterStringInputProps> = ({
  parameter,
  value,
  onValidationErrors,
  onChange,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const stringValidation = parameter.typing;

  const validateString = (value: string): void => {
    const newErrors: string[] = [];
    
    if (stringValidation) {
      if (stringValidation.minLength && value.length < stringValidation.minLength) {
        newErrors.push(`Minimum ${stringValidation.minLength} characters`);
      }
      if (stringValidation.maxLength && value.length > stringValidation.maxLength) {
        newErrors.push(`Maximum ${stringValidation.maxLength} characters`);
      }
      if (stringValidation.pattern && !new RegExp(stringValidation.pattern).test(value)) {
        newErrors.push('Invalid format');
      }
    }
    
    setErrors(newErrors);
    onValidationErrors?.(newErrors);
  };

  useEffect(() => {
    validateString(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    validateString(newValue);
    onChange?.(newValue);
  };

  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm">{parameter.description}</div>
      <InputText
        className="w-44"
        inputType="text"
        labelText=""
        value={value}
        onChange={handleChange}
        errors={errors}
      />
    </div>
  );
}; 