import { FC, useRef, useEffect, useState } from 'react';
import { VigilQRCode, VigilQRCodeScanner } from 'vigil-qr';

// Extend MediaTrackCapabilities to include torch property
interface ExtendedMediaTrackCapabilities extends MediaTrackCapabilities {
  torch?: boolean;
}

// Extend MediaTrackConstraintSet to include torch property
interface ExtendedMediaTrackConstraintSet extends MediaTrackConstraintSet {
  torch?: boolean;
}

interface VigilQRScanProps {
  onScan: (data: VigilQRCode<any>) => void;
  enableFlashlight?: boolean;
}

export const VigilQRScan: FC<VigilQRScanProps> = (props) => {
  // Create refs
  const elementVideo = useRef(null as unknown as HTMLVideoElement);
  const elementCanvas = useRef(null as unknown as HTMLCanvasElement);
  const videoStreamRef = useRef<MediaStream | null>(null);
  const [isFlashlightOn, setIsFlashlightOn] = useState(false);
  const [hasFlashlight, setHasFlashlight] = useState(false);

  // Toggle flashlight
  const toggleFlashlight = async () => {
    if (!videoStreamRef.current) return;
    
    try {
      const track = videoStreamRef.current.getVideoTracks()[0];
      const capabilities = track.getCapabilities() as ExtendedMediaTrackCapabilities;
      
      // Check if torch is supported
      if (!capabilities.torch) {
        console.log('Torch not supported on this device');
        return;
      }
      
      const newFlashlightState = !isFlashlightOn;
      await track.applyConstraints({
        advanced: [{ torch: newFlashlightState } as ExtendedMediaTrackConstraintSet]
      });
      
      setIsFlashlightOn(newFlashlightState);
    } catch (error) {
      console.error('Error toggling flashlight:', error);
    }
  };

  // Start scanning
  useEffect(() => {
    const canvasContext = elementCanvas.current.getContext("2d", { willReadFrequently: true });
    const scanner = VigilQRCodeScanner;

    let mounted = true;

    // Scan loop
    const timeout = setInterval(() => {
      if (!elementVideo.current) return;
      const canvasWidth = elementVideo.current.videoWidth;
      const canvasHeight = elementVideo.current.videoHeight;
      if (!canvasContext || !canvasWidth || !canvasHeight) {
        return;
      }

      if (elementCanvas.current.width != canvasWidth || elementCanvas.current.height != canvasHeight) {
        elementCanvas.current.width = canvasWidth;
        elementCanvas.current.height = canvasHeight;
      }

      canvasContext.drawImage(elementVideo.current, 0, 0, canvasWidth, canvasHeight);
      var imageData = canvasContext.getImageData(0, 0, canvasWidth, canvasHeight);
      var code = scanner.scan(imageData.data, imageData.width, imageData.height);
      code && props.onScan(code);
    }, 100);

    // Link video stream
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
      if (!mounted) {
        stream.getTracks().forEach(track => track.stop());
        return;
      }
      
      videoStreamRef.current = stream;
      elementVideo.current.srcObject = stream;
      elementVideo.current.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
      elementVideo.current.play();
      
      // Check if flashlight is available
      const track = stream.getVideoTracks()[0];
      const capabilities = track.getCapabilities() as ExtendedMediaTrackCapabilities;
      setHasFlashlight(!!capabilities.torch);
    });

    return () => {
      mounted = false;
      clearInterval(timeout);
      // Stop the video stream when the component is unmounted
      if (videoStreamRef.current) {
        videoStreamRef.current.getTracks().forEach(track => track.stop());
        videoStreamRef.current = null;
      }
    }
  }, []);

  return (
    <div className="relative">
      <canvas hidden={true} ref={elementCanvas}></canvas>
      <video className='rounded-xl' hidden={false} ref={elementVideo}></video>
      
      {props.enableFlashlight !== false && hasFlashlight && (
        <button 
          onClick={toggleFlashlight}
          className="absolute bottom-4 right-4 p-3 bg-white bg-opacity-70 rounded-full shadow-md"
          aria-label={isFlashlightOn ? "Turn off flashlight" : "Turn on flashlight"}
        >
          {isFlashlightOn ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M18 6c0 2-2 2-2 4v10a2 2 0 01-2 2h-4a2 2 0 01-2-2V10c0-2-2-2-2-4V2h12z" />
              <line x1="6" y1="6" x2="18" y2="6" />
              <line x1="12" y1="12" x2="12" y2="12" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M16 16v4a2 2 0 01-2 2h-4a2 2 0 01-2-2V10c0-2-2-2-2-4" />
              <path d="M7 2h11v4c0 2-2 2-2 4v1" />
              <line x1="11" y1="6" x2="18" y2="6" />
              <line x1="2" y1="2" x2="22" y2="22" />
            </svg>
          )}
        </button>
      )}
    </div>
  )
}