import { ProtocolConfig } from '../base';

export const ProtocolSendPublicReport: ProtocolConfig = (() => {
  const TAG_SEND_EMAIL = "SEND_EMAIL"

  return {
    config: {
      id: "send_public_report",
      name: "Send Report",
      description: "Send a link of a report via email. If you do not select any sites, the protocol will not run.",
      triggers: [{ type: "schedule", value: { durationMinutes: 30 } }],
      parameters: [
        {
          id: "to_emails",
          name: "To Emails",
          description: "List of emails to send the report to",
          required: true,
          typing: {
            type: "array"
          },
        },
        {
          id: "timeframe",
          name: "Timeframe",
          description: "Timeframe of the report",
          required: true,
          typing: {
            type: "option",
            options: [
              {
                value: "24h",
                label: "24 hours"
              },
              {
                value: "2d",
                label: "2 days"
              },
              {
                value: "7d",
                label: "7 days"
              },
            ],
          },
        },
        {
          id: "uuid_protocols",
          name: "Protocols",
          description: "The protocols to send the report of",
          required: true,
          typing: {
            type: "array",
            options: async (organization) => {
              const protocols = await organization.getProtocols();
              // Can only adopt patrol protocols for now. Can implement other protocols later
              const filteredProtocols = protocols.filter(protocol => protocol.id === 'device_patrol')
              return filteredProtocols.map(protocol => ({ value: protocol.uuid, label: protocol.name }));
            }
          },
        }
      ],
      members: [],
      deviceScreens: [],
    },
    device: {
      tick: async () => { },
    },
    server: {
      tick: async (context) => {
        const logs = await context.protocolInstance.getLogs();
        const parameters = context.protocolInstance.getParameters();
        const toEmails = parameters.to_emails as string[];
        const timeframe = parameters.timeframe as string;
        const uuidProtocols = parameters.uuid_protocols as string[];

        if (toEmails.length > 0) {
          const lastLogEmail = logs.filter(log => log.tags.includes(TAG_SEND_EMAIL)).pop()
          // If log exists, skip
          if (lastLogEmail) return

          // Get protocol instances from timeframe
          let millisecondsSubtract = 0
          if (timeframe === "24h") millisecondsSubtract = 24 * 60 * 60 * 1000
          if (timeframe === "2d") millisecondsSubtract = 2 * 24 * 60 * 60 * 1000
          if (timeframe === "7d") millisecondsSubtract = 7 * 24 * 60 * 60 * 1000
          const fromDateTime = new Date(context.protocolInstance.dateTimeStart.getTime() - millisecondsSubtract)
          const protocolInstances = await context.organization.getSDKProtocolInstances(fromDateTime)

          // Filter protocol instances by uuidProtocols
          const protocolInstancesFiltered = protocolInstances.filter(protocolInstance => uuidProtocols.includes(protocolInstance.protocolSnapshot.uuid))

          // Create reports
          const uuidReports: string[] = []
          for (const protocolInstance of protocolInstancesFiltered) {
            const uuidReport = await context.organization.createReport(protocolInstance)
            uuidReports.push(uuidReport)
          }

          // Send email
          await context.notification.sendEmails(toEmails.map(email => {
            return {
              type: 'REPORT',
              target: email,
              uuidProtocolInstanceReports: uuidReports
            }
          }))

          // Create log
          const protocols = await context.organization.getProtocols()
          const protocolsNames = protocols.filter(protocol => uuidProtocols.includes(protocol.uuid)).map(protocol => protocol.name).join(", ")
          await context.protocolInstance.createLog(`Send email report of ${protocolsNames} to ${toEmails.join(", ")}`, { uuidReports }, [TAG_SEND_EMAIL])
        }
      },
    }
  }
})()
