import { integer, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { protocols, IProtocol } from "./bean_protocol";
import { protocolInstanceLogs } from "./bean_protocol_instance_log";
import { protocolInstanceReports } from "./bean_protocol_instance_report";
import { protocolInstanceMembers } from "./bean_protocol_instance_member";
import { protocolInstanceRunners } from "./bean_protocol_instance_runner";

export const protocolInstances = sqliteTable('protocol_instances', {
  ...schemaBase(),
  dateTimeStart: integer('date_time_start', { mode: 'timestamp' }).notNull(),
  dateTimeEnd: integer('date_time_end', { mode: 'timestamp' }).notNull(),
  uuidProtocol: text('uuid_protocol').notNull().references(() => protocols.uuid),
  protocolSnapshot: customJsonColumn<IProtocol>('protocol').notNull(),
});

export const protocolInstancesRelations = relations(protocolInstances, ({ one, many }) => ({
  protocol: one(protocols, {
    fields: [protocolInstances.uuidProtocol],
    references: [protocols.uuid],
  }),
  protocolInstanceLogs: many(protocolInstanceLogs),
  protocolInstanceReports: many(protocolInstanceReports),
  protocolInstanceMembers: many(protocolInstanceMembers),
  protocolInstanceRunners: many(protocolInstanceRunners),
}));
