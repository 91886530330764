import { BuildTTComFunctions } from "tt-coms-functions-client";
import { VigilFunctions, VigilContextClient } from "vigil-datamodel";

export class VigilClient {
  public readonly functions;

  constructor(params: { functionsURL: string, tokenCallback?: () => string | null, client_type: 'mobile' | 'web', client_version: number }) {
    // Init ttcoms and get collections
    this.functions = BuildTTComFunctions({
      functions: VigilFunctions,
      endpoint: params.functionsURL,
      contextFn: async () => {
        const token = params.tokenCallback ? params.tokenCallback() : "";
        return {
          token: token,
          client_type: params.client_type,
          client_version: params.client_version
        } as VigilContextClient
      }
    });
  }
}
