import { Beacon, Device, Protocol, SDKOrganizationBase, Site, User } from "vigil-protocol";
import { IOrganizationSelect } from 'vigil-datamodel';
import { VigilClient } from "vigil-client";

export class SDKOrganizationImpl implements SDKOrganizationBase {
  uuid: string;
  private organization: IOrganizationSelect;
  private vigil: VigilClient;

  constructor(organization: IOrganizationSelect, vigil: VigilClient) {
    this.organization = organization;
    this.uuid = organization.uuid;
    this.vigil = vigil;
  }

  async getOrganizationName(): Promise<string> { return this.organization.name }

  async getBeacons(): Promise<Beacon[]> {
    return await this.vigil.functions.findManyBeacons({ uuidOrganization: this.organization.uuid })
  }

  async getBeacon(uuid: string): Promise<Beacon | null> {
    const beacon = await this.vigil.functions.findOneBeacon({ uuidOrganization: this.organization.uuid, uuid })
    if (!beacon) return null;
    return beacon
  }

  async getBeaconsLinkedToSite(uuidSite: string): Promise<Beacon[]> {
    return await this.vigil.functions.findManyBeaconsLinkedToSite({ uuidOrganization: this.organization.uuid, uuidSite })
  }

  async getSites(): Promise<Site[]> {
    const sites = await this.vigil.functions.findManySites({ uuidOrganization: this.organization.uuid })
    return sites
  }

  async getSite(uuid: string): Promise<Site | null> {
    const site = await this.vigil.functions.findOneSite({ uuidOrganization: this.organization.uuid, uuid })
    if (!site) return null;
    return site
  }

  async getSitesLinkedToBeacon(uuidBeacon: string): Promise<Site[]> {
    const sites = await this.vigil.functions.findManySitesLinkedToBeacon({ uuidOrganization: this.organization.uuid, uuidBeacon })
    return sites
  }

  async getDevices(): Promise<Device[]> {
    const devices = await this.vigil.functions.findManyDevices({ uuidOrganization: this.organization.uuid })
    return devices.map(device => ({
      ...device,
      imei1: device.imei1 ?? '',
    }))
  }

  async getDevice(uuid: string): Promise<Device | null> {
    const device = await this.vigil.functions.findOneDevice({ uuidOrganization: this.organization.uuid, uuid })
    if (!device) return null;
    return {
      ...device,
      imei1: device.imei1 ?? '',
    }
  }

  async getUsers(): Promise<User[]> {
    const users = await this.vigil.functions.findManyUsers({ uuidOrganization: this.organization.uuid })
    return users.map(user => ({
      ...user,
      idNumber: user.idNumber ?? '',
      email: user.email ?? '',
      mobile: user.mobile ?? '',
    }))
  }

  async getUser(uuid: string): Promise<User | null> {
    const user = await this.vigil.functions.findOneUser({ uuidOrganization: this.organization.uuid, uuid })
    if (!user) return null;
    return {
      ...user,
      idNumber: user.idNumber ?? '',
      email: user.email ?? '',
      mobile: user.mobile ?? '',
    }
  }

  async getProtocols(): Promise<Protocol[]> {
    const protocols = await this.vigil.functions.findManyProtocols({ uuidOrganization: this.organization.uuid })
    return protocols.map(protocol => ({
      ...protocol,
      triggers: protocol.triggers as any,
      config: "TODO: Need to serialize protocol config" as any
    }))
  }

  async getProtocol(uuid: string): Promise<Protocol | null> {
    const protocol = await this.vigil.functions.findOneProtocol({ uuidOrganization: this.organization.uuid, uuid })
    if (!protocol) return null;
    return {
      ...protocol,
      triggers: protocol.triggers as any,
      config: "TODO: Need to serialize protocol config" as any
    }
  }

  async getProtocolsLinkedToMember(memberUuid: string): Promise<Protocol[]> {
    const protocols = await this.vigil.functions.findManyProtocolsLinkedToMember({ uuidOrganization: this.organization.uuid, uuidMember: memberUuid })
    return protocols.map(protocol => ({
      ...protocol,
      triggers: protocol.triggers as any,
      config: "TODO: Need to serialize protocol config" as any
    }))
  }
}
