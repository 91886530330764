import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../router/routes';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { IProtocolInstanceSelect } from 'vigil-datamodel';
import { ContextOrganization } from '../../providers/provider_organization';
import { IconDocumentMagnifyingGlass, IconListBullet } from '../../components/icons';
import { Tabs } from '../../components/tabs';

interface ScreenHomeProtocolInstanceProps { }

export const ScreenHomeProtocolInstance: React.FC<ScreenHomeProtocolInstanceProps> = (props) => {
  const navigate = useNavigate();
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const params = useParams();

  const uuidProtocol = params['uuid'] || '';
  const uuidProtocolInstance = params['instance_uuid'] || '';

  const [stateError, setError] = useState<string>('');
  const [stateProtocolInstanceLoading, setProtocolInstanceLoading] = useState(true);
  const [stateProtocolInstance, setProtocolInstance] = useState<IProtocolInstanceSelect>();

  useEffect(() => { fetchProtocolInstance(); }, [uuidProtocolInstance]);

  async function fetchProtocolInstance() {
    try {
      if (!organization.data?.uuid) throw new Error('Organization not found');
      setProtocolInstanceLoading(true);
      const protocolInstance = await vigil.functions.findOneProtocolInstance({ uuidOrganization: organization.data.uuid, uuid: uuidProtocolInstance });
      setProtocolInstance(protocolInstance);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setProtocolInstanceLoading(false);
    }
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  function handleTabSelect(id: string) {
    navigate(`${ROUTES.ROUTE_HOME_PROTOCOL_INSTANCE.replace(':uuid', uuidProtocol).replace(':instance_uuid', uuidProtocolInstance)}/${id}`);
  }

  if (stateProtocolInstanceLoading || !stateProtocolInstance) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full h-full flex flex-col'>
      <Breadcrumbs
        crumbs={[
          { id: 'protocols', onClick: () => navigate(ROUTES.ROUTE_HOME_PROTOCOLS), text: 'Protocols' },
          { id: uuidProtocol, onClick: () => navigate(ROUTES.ROUTE_HOME_PROTOCOL.replace(':uuid', uuidProtocol)), text: stateProtocolInstance.protocolSnapshot.name },
          { id: uuidProtocolInstance, onClick: () => { }, text: 'Instance' }
        ]}
      />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <Tabs selectedId={getSelectedId()} onSelect={handleTabSelect} items={[
        {
          icon: IconDocumentMagnifyingGlass,
          id: 'overview',
          text: 'Overview'
        },
        {
          icon: IconListBullet,
          id: 'logs',
          text: 'Logs'
        },
      ]} />

      <div className='w-full flex-grow'>
        <Outlet context={{ protocolInstanceParent: stateProtocolInstance, fetchProtocolInstanceParent: fetchProtocolInstance }} />
      </div>
    </div>
  )
};
