import { integer, real, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { devices } from "./bean_device";
import { relations } from "drizzle-orm";

export const deviceStateLogs = sqliteTable('device_state_logs', {
  ...schemaBase(),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
  networkType: text('network_type', { enum: ["wifi", "cellular", "none", "unknown", "bluetooth", "ethernet", "wimax", "vpn", "other"] }).notNull(),
  cellStrength: real('cell_strength').notNull(),
  cellNetwork: text('cell_network').notNull(),
  locationAcc: real('location_acc'),
  locationLng: real('location_lng'),
  locationLat: real('location_lat'),
  locationAlt: real('location_alt'),
  chargeLevel: real('charge_level'),
  chargeState: text('charge_state', { enum: ["plugged", "none"] }).notNull(),
  stepCountHourly: real('step_count_hourly'),
  isServerReachable: integer('is_server_reachable').notNull(),
});

export const deviceStateLogsRelations = relations(deviceStateLogs, ({ one }) => ({
  device: one(devices, {
    fields: [deviceStateLogs.uuidDevice],
    references: [devices.uuid],
  }),
}));
