import { useEffect, useState } from 'react';
import { ProtocolParameter, ProtocolParamaterTypeDuration } from 'vigil-protocol';
import { InputDuration, DurationState } from '../../../../components/input_duration';

type ProtocolParameterDuration = ProtocolParameter & { typing: ProtocolParamaterTypeDuration };

interface ProtocolParameterDurationInputProps {
  parameter: ProtocolParameterDuration;
  // This is the total minutes value
  value: number;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: number) => void;
}

export const ProtocolParameterDurationInput: React.FC<ProtocolParameterDurationInputProps> = ({
  parameter,
  value,
  onValidationErrors,
  onChange,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  const validateDuration = (duration: DurationState): void => {
    const totalMinutes = (duration.hours || 0) * 60 + (duration.minutes || 0);
    const newErrors: string[] = [];

    if (totalMinutes === 0 && parameter.required) {
      newErrors.push('Duration cannot be zero');
    }
    if (totalMinutes > 24 * 60) {
      newErrors.push('Duration cannot be more than 24 hours');
    }

    setErrors(newErrors);
    onValidationErrors?.(newErrors);
  };

  useEffect(() => {
    validateDuration({ hours, minutes });
  }, [value]);

  const handleChange = (duration: DurationState): void => {
    const totalMinutes = (duration.hours || 0) * 60 + (duration.minutes || 0);
    validateDuration(duration);
    onChange?.(totalMinutes);
  };

  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm">{parameter.description}</div>
      <InputDuration
        labelText=""
        value={{ hours, minutes }}
        minutesStep={1}
        onChange={handleChange}
        errors={errors}
      />
    </div>
  );
}; 