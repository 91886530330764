import { Beacon, Device, ProtocolConfig, ProtocolContextDevice, Site } from '../base';

export const ProtocolDevicePatrol: ProtocolConfig = (() => {
  type StatePatrol = {
    id: 'state-patrol'
    data: {
      errorAlert: string,
      showStartAlert: boolean,
      lastBeaconPickedTime: Date,
      timeBeforeScanMinutes: number,
      scanWindowMinutes: number,
      canScanBeacon: boolean,
      beaconsScanned: Beacon[],
      beaconsRemaining: Beacon[],
      beaconsMissed: Beacon[],
      nextBeacon: Beacon,
    }
  }

  type StateRest = {
    id: 'state-rest'
    data: {
      restTimeStart: Date,
      restTimeMinutes: number,
    }
  }

  type ProtocolInstanceState = StatePatrol | StateRest

  const SCREEN_SIGN_IN_REQUIRED = "SCREEN_SIGN_IN_REQUIRED"
  const SCREEN_HOME_MAP = "SCREEN_HOME_MAP"
  const SCREEN_HOME_PROFILE = "SCREEN_HOME_PROFILE"

  const SOUND_SIGN_IN_ALERT = "SOUND_SIGN_IN_ALERT"
  const SOUND_PATROL_START_ALERT = "SOUND_PATROL_START_ALERT"
  const SOUND_NEW_BEACON_PICKED = "SOUND_NEW_BEACON_PICKED"
  const SOUND_BEACON_SCAN_SUCCESS = "SOUND_BEACON_SCAN_SUCCESS"
  // const SOUND_BEACON_SCAN_ERROR = "SOUND_BEACON_SCAN_ERROR"
  const SOUND_PANIC_ACKNOWLEDGED = "SOUND_PANIC_ACKNOWLEDGED"

  const TAG_SIGN_IN_ALERT = "SIGN_IN_ALERT"
  const TAG_USER_ACKNOWLEDGED_SIGN_IN = "USER_ACKNOWLEDGED_SIGN_IN"
  const TAG_NO_BEACONS = "NO_BEACONS"
  const TAG_USER_SIGN_IN = "USER_SIGN_IN"
  const TAG_USER_SIGN_OUT = "USER_SIGN_OUT"
  const TAG_SIGN_IN_MISSED_SERVER = "SIGN_IN_MISSED_SERVER"
  const TAG_NO_SITES_LINKED = "NO_SITES_LINKED"
  const TAG_BEACON_SCANNED = "BEACON_SCANNED"
  const TAG_PANIC = "PANIC"
  const TAG_BEACON_SCAN_ERROR = "BEACON_SCAN_ERROR"
  const TAG_PATROL_SEQUENCE_STARTED = "PATROL_SEQUENCE_STARTED"
  const TAG_PATROL_SEQUENCE_SUCCESS = "PATROL_SEQUENCE_SUCCESS"
  const TAG_PATROL_SEQUENCE_FAILED = "PATROL_SEQUENCE_FAILED"
  const TAG_MISSED_BEACON_SCAN = "MISSED_BEACON_SCAN"

  function pickNextBeacon(beacons: Beacon[], mode: 'random' | 'alphabetical') {
    if (mode === 'random') return beacons[Math.floor(Math.random() * beacons.length)]
    return beacons.sort((a, b) => a.name.localeCompare(b.name))[0]
  }

  function getInitRestState(now: Date, restTimeMinutes: number): StateRest {
    return {
      id: 'state-rest',
      data: {
        restTimeStart: now,
        restTimeMinutes,
      }
    }
  }

  function getInitPatrolState(now: Date, timeBeforeScanMinutes: number, scanWindowMinutes: number, beacons: Beacon[], beaconSelectionMode: 'random' | 'alphabetical'): StatePatrol {
    const nextBeacon = pickNextBeacon(beacons, beaconSelectionMode)
    return {
      id: 'state-patrol',
      data: {
        errorAlert: '',
        showStartAlert: true,
        lastBeaconPickedTime: now,
        timeBeforeScanMinutes,
        scanWindowMinutes,
        canScanBeacon: timeBeforeScanMinutes == 0,
        beaconsScanned: [],
        beaconsRemaining: beacons.filter((b) => b.uuid !== nextBeacon.uuid),
        beaconsMissed: [],
        nextBeacon,
      }
    }
  }

  async function _handlePatrolCompletedLogCreation(context: ProtocolContextDevice, missedBeacons: Beacon[]) {
    if (missedBeacons.length > 0) {
      await context.protocolInstance.createLog("Patrol sequence failed, missed beacons: " + missedBeacons.map((b) => b.name).join(", "), { uuidBeaconsMissed: missedBeacons.map((b) => b.uuid) }, [TAG_PATROL_SEQUENCE_FAILED])
    } else {
      await context.protocolInstance.createLog("Patrol sequence completed successfully", {}, [TAG_PATROL_SEQUENCE_SUCCESS])
    }
  }

  async function _handleTick(context: ProtocolContextDevice): Promise<StateRest | StatePatrol | null> {
    const now = new Date();
    const parameters = context.protocolInstance.getParameters();
    const restTimeMinutes = parameters.rest_time_minutes as number;
    const timeBeforeScanMinutes = parameters.time_before_scan_minutes as number;
    const scanWindowMinutes = parameters.scan_window_minutes as number;
    const beaconSelectionMode = parameters.beacon_selection_mode as 'random' | 'alphabetical';

    const currentState = await context.protocolInstance.getLocalState() as unknown as ProtocolInstanceState | null;
    console.log(`onTick: currentState: ${JSON.stringify(currentState)}`)

    const members = await context.protocolInstance.getMembers();
    // TODO: Handle multiple sites
    const site = members.sites[0];
    const beacons = await context.organization.getBeaconsLinkedToSite(site.uuid)

    // If no state, set to rest
    if (!currentState || Object.keys(currentState).length === 0) {
      return getInitRestState(now, restTimeMinutes)
    }

    // If rest state
    if (currentState.id === 'state-rest') {
      // If rest time has passed, set to patrol
      if (now > new Date(currentState.data.restTimeStart.valueOf() + currentState.data.restTimeMinutes * 60 * 1000)) {
        await context.protocolInstance.createLog("Patrol sequence started", {}, [TAG_PATROL_SEQUENCE_STARTED])
        // Alert sound to alert user that the patrol sequence has started.
        if (context.device.getAudio()?.id !== SOUND_PATROL_START_ALERT) {
          context.device.setAudio({ id: SOUND_PATROL_START_ALERT, base64Audio: context.vigil.audioBase64.alert_2, loop: 50 })
        }
        return getInitPatrolState(now, timeBeforeScanMinutes, scanWindowMinutes, beacons, beaconSelectionMode)
      }
    }

    // If patrol state
    if (currentState.id === 'state-patrol') {
      // Check if time to update canScanBeacon status (transition from waiting period to scan window)
      if (!currentState.data.canScanBeacon &&
        now > new Date(currentState.data.lastBeaconPickedTime.valueOf() + currentState.data.timeBeforeScanMinutes * 60 * 1000)) {
        // Transition to scan window
        context.device.setAudio({ id: SOUND_BEACON_SCAN_SUCCESS, base64Audio: context.vigil.audioBase64.success_1, loop: 1 })
        return {
          id: 'state-patrol',
          data: {
            ...currentState.data,
            canScanBeacon: true,
          }
        }
      }

      // Calculate the total time window (waiting period + scan window)
      const totalTimeWindow = currentState.data.timeBeforeScanMinutes + currentState.data.scanWindowMinutes;

      // If totalTimeWindow has not yet passed, continue
      if (now < new Date(currentState.data.lastBeaconPickedTime.valueOf() + totalTimeWindow * 60 * 1000)) {
        return null
      }

      // This means we missed the beacon
      const missedBeacons = [...currentState.data.beaconsMissed, currentState.data.nextBeacon]
      await context.protocolInstance.createLog("Missed beacon scan, beacon missed: " + currentState.data.nextBeacon.name, { uuidBeacon: currentState.data.nextBeacon.uuid }, [TAG_MISSED_BEACON_SCAN])

      // If the patrol is complete
      if (currentState.data.beaconsRemaining.length === 0) {
        await _handlePatrolCompletedLogCreation(context, missedBeacons)
        return getInitRestState(now, restTimeMinutes)
      }
      // If patrol is not complete, select next beacon
      else {
        const nextBeacon = pickNextBeacon(currentState.data.beaconsRemaining, beaconSelectionMode)
        context.device.setAudio({ id: SOUND_NEW_BEACON_PICKED, base64Audio: context.vigil.audioBase64.alert_4, loop: 1 })
        return {
          id: 'state-patrol',
          data: {
            ...currentState.data,
            lastBeaconPickedTime: now,
            timeBeforeScanMinutes,
            scanWindowMinutes,
            canScanBeacon: timeBeforeScanMinutes == 0, // Reset to waiting period
            beaconsRemaining: currentState.data.beaconsRemaining.filter((b) => b.uuid !== nextBeacon.uuid),
            beaconsMissed: [...currentState.data.beaconsMissed, currentState.data.nextBeacon],
            nextBeacon,
          }
        }
      }
    }
    return null;
  }

  async function _handleBeaconScan(context: ProtocolContextDevice, scannedBeaconUuid: string): Promise<StatePatrol | StateRest | null> {
    const now = new Date();
    const parameters = context.protocolInstance.getParameters();
    const restTimeMinutes = parameters.rest_time_minutes as number;
    const timeBeforeScanMinutes = parameters.time_before_scan_minutes as number;
    const scanWindowMinutes = parameters.scan_window_minutes as number;
    const beaconSelectionMode = parameters.beacon_selection_mode as 'random' | 'alphabetical';

    const currentState = await context.protocolInstance.getLocalState() as unknown as ProtocolInstanceState | null;
    console.log(`onBeaconScan: currentState: ${JSON.stringify(currentState)}`)

    const members = await context.protocolInstance.getMembers();
    // TODO: Handle multiple sites
    const site = members.sites[0];
    const beacons = await context.organization.getBeaconsLinkedToSite(site.uuid)

    const device = await context.device.getDevice();
    const user = await context.device.getUser();
    const userName = user ? `${user.firstName} ${user.lastName}` : "unknown"

    // If no state, set to rest
    if (!currentState || Object.keys(currentState).length === 0) {
      return getInitRestState(now, restTimeMinutes)
    }

    // If rest state
    if (currentState.id === 'state-rest') {
      await context.protocolInstance.createLog(`Patrol sequence started`, {}, [TAG_PATROL_SEQUENCE_STARTED])
      return getInitPatrolState(now, timeBeforeScanMinutes, scanWindowMinutes, beacons, beaconSelectionMode)
    }

    // If patrol state
    if (currentState.id === 'state-patrol') {
      // If we are still in the waiting period
      if (!currentState.data.canScanBeacon) {
        const waitTimeEnd = new Date(currentState.data.lastBeaconPickedTime.valueOf() + currentState.data.timeBeforeScanMinutes * 60 * 1000);
        const timeRemaining = Math.ceil((waitTimeEnd.valueOf() - now.valueOf()) / (60 * 1000));

        return {
          id: 'state-patrol',
          data: {
            ...currentState.data,
            errorAlert: `Scanning not allowed yet. Your next beacon will be ${currentState.data.nextBeacon.name}. Please wait ${timeRemaining} more minute${timeRemaining !== 1 ? 's' : ''} before scanning.`,
          }
        }
      }

      // TODO: FIX

      // // If it is the wrong beacon
      // if (currentState.data.nextBeacon.uuid !== scannedBeaconUuid) {
      //   context.device.setAudio({ id: SOUND_BEACON_SCAN_ERROR, base64Audio: context.vigil.audioBase64.alert_3, loop: 1 })
      //   await context.protocolInstance.createLog(
      //     `Wrong beacon scanned. Beacon scanned uuid: ${scannedBeaconUuid} but expected uuid: ${currentState.data.nextBeacon.uuid} by user: ${userName} on device: ${device.uuid}`,
      //     { uuidBeacon: scannedBeaconUuid, uuidExpected: currentState.data.nextBeacon.uuid, uuidDevice: device.uuid, uuidUser: user?.uuid ?? "unknown" }, [TAG_BEACON_SCAN_ERROR])
      //   return {
      //     id: 'state-patrol',
      //     data: {
      //       ...currentState.data,
      //       // errorAlert: 'Wrong beacon scanned. Please make sure to scan the RED beacon with name ' + currentState.data.nextBeacon.name,
      //       errorAlert: 'Wrong beacon scanned. Please make sure to scan the beacon with name ' + currentState.data.nextBeacon.name,
      //     }
      //   }
      // }

      // // If it is the correct beacon
      // if (currentState.data.nextBeacon.uuid === scannedBeaconUuid) {
      context.device.setAudio({ id: SOUND_BEACON_SCAN_SUCCESS, base64Audio: context.vigil.audioBase64.success_1, loop: 1 })
      await context.protocolInstance.createLog(
        `Correct Beacon scanned with name: ${currentState.data.nextBeacon.name} and uuid: ${currentState.data.nextBeacon.uuid} by user: ${userName} on device: ${device.uuid}`,
        { uuidBeacon: currentState.data.nextBeacon.uuid, uuidDevice: device.uuid, uuidUser: user?.uuid ?? "unknown" }, [TAG_BEACON_SCANNED])

      const remainingBeacons = currentState.data.beaconsRemaining.filter((b) => b.uuid !== scannedBeaconUuid)

      // If the patrol is not complete
      if (remainingBeacons.length > 0) {
        const scannedBeacon = beacons.find((b) => b.uuid === scannedBeaconUuid)!

        // Pick next beacon immediately after a successful scan
        const nextBeacon = pickNextBeacon(remainingBeacons, beaconSelectionMode);
        return {
          id: 'state-patrol',
          data: {
            ...currentState.data,
            lastBeaconPickedTime: now,
            timeBeforeScanMinutes,
            scanWindowMinutes,
            canScanBeacon: timeBeforeScanMinutes == 0, // Reset to waiting period for the next beacon
            beaconsScanned: [...currentState.data.beaconsScanned, scannedBeacon],
            beaconsRemaining: remainingBeacons.filter(b => b.uuid !== nextBeacon.uuid),
            nextBeacon,
          }
        }
      }
      // If the patrol is complete
      else {
        await _handlePatrolCompletedLogCreation(context, currentState.data.beaconsMissed)
        return getInitRestState(now, restTimeMinutes)
      }
      // }
    }
    return null;
  }

  async function _handleCloseErrorAlert(context: ProtocolContextDevice): Promise<StatePatrol | null> {
    const currentState = await context.protocolInstance.getLocalState() as StatePatrol | null;
    if (!currentState) return null;

    if (currentState.data.errorAlert) {
      return {
        id: 'state-patrol',
        data: {
          ...currentState.data,
          errorAlert: '',
        }
      }
    }
    return null;
  }

  async function _handleCloseStartAlert(context: ProtocolContextDevice): Promise<StatePatrol | null> {
    const currentState = await context.protocolInstance.getLocalState() as StatePatrol | null;
    if (!currentState) return null;

    if (currentState.data.showStartAlert) {
      context.device.setAudio(null)
      return {
        id: 'state-patrol',
        data: {
          ...currentState.data,
          showStartAlert: false,
        }
      };
    }
    return null;
  }

  async function onTick(context: ProtocolContextDevice) {
    const newState = await _handleTick(context)
    if (!newState) return;
    await context.protocolInstance.setLocalState(newState as any)
  }

  async function onBeaconScan(context: ProtocolContextDevice, scannedBeaconUuid: string) {
    const newState = await _handleBeaconScan(context, scannedBeaconUuid)
    if (!newState) return;
    await context.protocolInstance.setLocalState(newState as any)
  }

  async function onCloseErrorAlert(context: ProtocolContextDevice) {
    const newState = await _handleCloseErrorAlert(context)
    if (!newState) return;
    await context.protocolInstance.setLocalState(newState as any)
  }

  async function onCloseStartAlert(context: ProtocolContextDevice) {
    const newState = await _handleCloseStartAlert(context)
    if (!newState) return;
    await context.protocolInstance.setLocalState(newState as any)
  }

  return {
    config: {
      id: "device_patrol",
      name: "Device Patrol",
      description: "Protocol that runs on a device to patrol a site/sites. This protocol will force the user to sign in to the device before it can be used.",
      triggers: [{ type: "schedule" }],
      parameters: [
        {
          id: "rest_time_minutes",
          name: "Rest Time (HH:MM)",
          description: "Specifies the rest period after completing a patrol sequence, allowing the guard to rest before starting the next cycle.",
          required: true,
          typing: {
            type: "duration",
          }
        },
        {
          id: "time_before_scan_minutes",
          name: "Time Before Scan (HH:MM)",
          description: "Specifies the waiting period before the user can start scanning the next beacon in the patrol sequence.",
          required: false,
          typing: {
            type: "duration",
          }
        },
        {
          id: "scan_window_minutes",
          name: "Scan Window Duration (HH:MM)",
          description: "Specifies the maximum time window the user has to scan a beacon before it counts as missed and the next beacon is selected.",
          required: true,
          typing: {
            type: "duration",
          }
        },
        {
          id: "beacon_selection_mode",
          name: "Beacon Selection Mode",
          description: "Specifies the mode for selecting beacons in the patrol sequence.",
          required: true,
          typing: {
            type: "option",
            options: [
              { value: "alphabetical", label: "Alphabetical" },
              { value: "random", label: "Random" },
            ],
          }
        }
      ],
      members: ['sites'],
      deviceScreens: [{
        id: SCREEN_SIGN_IN_REQUIRED,
        component: ({ context }) => {
          const c = context.components;
          return (<>
            <c.Modal isOpen={true} onClose={() => { }}>
              <c.Text variant="h3" className="py-4 text-black text-center" center={true}>Sign In Required</c.Text>
              <c.Text variant="p" className="py-4 text-black text-center" center={true}>User sign in is required in order to start the patrol.</c.Text>
              <c.Button
                text="OK"
                variant="btn-primary"
                className="text-white text-center"
                onPress={async () => {
                  context.device.setAudio(null)
                  context.device.setScreen(null)
                  const device = await context.device.getDevice()
                  await context.protocolInstance?.createLog("User acknowledged sign in alert on Device: " + device.uuid, {}, [TAG_USER_ACKNOWLEDGED_SIGN_IN])
                }}
              />
            </c.Modal>
          </>)
        }
      }, {
        id: SCREEN_HOME_MAP,
        component: ({ context }) => {
          const r = context.react;
          const c = context.components;
          const s = context.device.getScreen();

          const [site, setSite] = r.useState<Site | null>(null)
          const [beacons, setBeacons] = r.useState<Beacon[]>([])
          const [mapBeacons, setMapBeacons] = r.useState<{ id: string, latitude: number, longitude: number, state: "target" | "scanned" | "normal" | "missed" }[]>([])
          const [statusBar, setStatusBar] = r.useState<{ text: string | null, color: 'primary' | 'error' | 'info' | null } | null>(null)
          const [protocolInstanceState, setProtocolInstanceState] = r.useState<ProtocolInstanceState | null>(null)

          const [showStartAlert, setShowStartAlert] = r.useState(false)
          const [errorAlert, setErrorAlert] = r.useState('')

          async function fetchBeacons() {
            const members = await context.protocolInstance.getMembers();
            // TODO: Handle multiple sites
            const site = members.sites[0];
            setSite(site)
            const beacons = await context.organization.getBeaconsLinkedToSite(site.uuid)
            setBeacons(beacons)
          }

          // Initialize
          r.useEffect(() => { fetchBeacons() }, [])

          const updateState = async () => {
            const state = await context.protocolInstance.getLocalState() as unknown as ProtocolInstanceState | null
            setProtocolInstanceState(state)
          }

          // Update Protocol Instance State
          r.useEffect(() => { updateState() }, [context.protocolInstance])

          // Update UI when state changes
          r.useEffect(() => {
            if (!protocolInstanceState) return;

            setShowStartAlert(protocolInstanceState.id === 'state-patrol' && protocolInstanceState.data.showStartAlert)
            setErrorAlert(protocolInstanceState.id === 'state-patrol' ? protocolInstanceState.data.errorAlert : '')

            if (protocolInstanceState.id === 'state-rest' && beacons.length === 0) {
              setStatusBar({
                text: `No beacons found for site ${site?.name}`,
                color: 'error'
              })
            } else if (protocolInstanceState.id === 'state-rest' && beacons.length > 0) {
              setMapBeacons(beacons.map((b) => ({ id: b.name, latitude: b.latitude, longitude: b.longitude, state: "normal" })))
              setStatusBar({
                text: `Resting until ${new Date(protocolInstanceState.data.restTimeStart.valueOf() + protocolInstanceState.data.restTimeMinutes * 60 * 1000).toLocaleString()}`,
                color: 'primary'
              })
            } else if (protocolInstanceState.id === 'state-patrol') {
              // If the next beacon has not been scanned
              setMapBeacons([
                ...protocolInstanceState.data.beaconsScanned.map((bs) => ({ id: bs.name, latitude: bs.latitude, longitude: bs.longitude, state: "scanned" as const })),
                ...protocolInstanceState.data.beaconsRemaining.map((br) => ({ id: br.name, latitude: br.latitude, longitude: br.longitude, state: "normal" as const })),
                ...protocolInstanceState.data.beaconsMissed.map((bm) => ({ id: bm.name, latitude: bm.latitude, longitude: bm.longitude, state: "missed" as const })),
                { id: protocolInstanceState.data.nextBeacon.name, latitude: protocolInstanceState.data.nextBeacon.latitude, longitude: protocolInstanceState.data.nextBeacon.longitude, state: "target" },
              ])

              // If we're in the waiting period
              if (!protocolInstanceState.data.canScanBeacon) {
                const waitEnd = new Date(protocolInstanceState.data.lastBeaconPickedTime.valueOf() + protocolInstanceState.data.timeBeforeScanMinutes * 60 * 1000);
                setStatusBar({
                  text: `Next beacon: ${protocolInstanceState.data.nextBeacon.name} - Waiting period until ${waitEnd.toLocaleString()}`,
                  color: 'info'
                })
              } else {
                // In scan window
                const scanEnd = new Date(protocolInstanceState.data.lastBeaconPickedTime.valueOf() +
                  (protocolInstanceState.data.timeBeforeScanMinutes + protocolInstanceState.data.scanWindowMinutes) * 60 * 1000);
                setStatusBar({
                  text: `Scan beacon with name ${protocolInstanceState.data.nextBeacon.name} before ${scanEnd.toLocaleString()}`,
                  color: 'error'
                })
              }
            }
          }, [protocolInstanceState, beacons])

          return (<>
            <c.LayoutVertical>
              {/* Modal for handling errors that can happen on handle state */}
              <c.Modal isOpen={errorAlert.length > 0} onClose={() => { }}>
                <c.LayoutVertical>
                  <c.Text className='text-center text-black font-bold' variant='h4'>Error</c.Text>
                  <c.Text className='py-4 text-center text-black'> {errorAlert} </c.Text>
                  <c.LayoutVertical className='p-1' />
                  <c.Button onPress={async () => {
                    await onCloseErrorAlert(context)
                    updateState()
                  }} variant='btn-error' className='text-white' text="OK" />
                </c.LayoutVertical>
              </c.Modal>

              {/* Modal for alerting user of patrol that is starting */}
              <c.Modal isOpen={showStartAlert} onClose={() => { }}>
                <c.LayoutVertical>
                  <c.Text className='text-center text-black font-bold' variant='h4'>Start Patrol</c.Text>
                  <c.Text className='py-4 text-center text-black'> Patrol sequence has started, please scan the next beacon and follow the instructions on the status bar. </c.Text>
                  <c.LayoutVertical className='p-1' />
                  <c.Button onPress={async () => {
                    await onCloseStartAlert(context)
                    updateState()
                  }} variant='btn-primary' className='text-white' text="OK" />
                </c.LayoutVertical>
              </c.Modal>

              {/* Map */}
              <c.Map
                beacons={mapBeacons}
                showUserLocation={true}
                onPanic={async () => {
                  // We need to have a callback, otherwise the panic button will not show
                  console.log("onPanic")
                }}
                onScan={async (data) => {
                  if (data.id === 'beacon') {
                    await onBeaconScan(context, data.uuid)
                    updateState()
                  }
                }}
              >
                {/* Status Bar */}
                {statusBar && (
                  <c.Text className={`w-full text-white text-center bg-${statusBar.color}`}> {statusBar.text} </c.Text>
                )}

                {/* Protocol Instance Info */}
                <c.LayoutVertical className='pointer-events-none justify-center'>
                  <c.Text className='text-white text-center'>You currently have a protocol instance running</c.Text>
                  <c.Text className='text-white text-center'>Please follow the instructions on the status bar</c.Text>
                  <c.Text className='text-gray-400 text-center'>Protocol Instance UUID:</c.Text>
                  <c.Text className='text-white text-center'>{context.protocolInstance?.uuid}</c.Text>
                  {protocolInstanceState?.id === 'state-patrol' && (
                    <>
                      <c.Text className='text-gray-400 text-center'>Total beacons:</c.Text>
                      <c.Text className='text-white text-center'>{beacons.length}</c.Text>
                      <c.Text className='text-gray-400 text-center'>Beacons scanned successfully:</c.Text>
                      <c.Text className='text-white text-center'>{protocolInstanceState?.data.beaconsScanned.length}</c.Text>
                      <c.Text className='text-gray-400 text-center'>Beacons remaining:</c.Text>
                      <c.Text className='text-white text-center'>{protocolInstanceState?.data.beaconsRemaining.length + 1}</c.Text>
                      <c.Text className='text-gray-400 text-center'>Beacons missed:</c.Text>
                      <c.Text className='text-white text-center'>{protocolInstanceState?.data.beaconsMissed.length}</c.Text>
                    </>
                  )}
                </c.LayoutVertical>
              </c.Map>

              {/* Bottom Nav */}
              <c.BottomNav>
                <c.BottomNavItem icon={<c.Icon icon='home' className="w-6 h-6" />} text="Map"
                  isActive={s?.id === SCREEN_HOME_MAP}
                  onPress={() => {
                    context.device.setScreen({ id: SCREEN_HOME_MAP })
                  }} />
                <c.BottomNavItem icon={<c.Icon icon='user' className="w-6 h-6" />} text="Profile"
                  isActive={s?.id === SCREEN_HOME_PROFILE}
                  onPress={() => {
                    context.device.setScreen({ id: SCREEN_HOME_PROFILE })
                  }} />
              </c.BottomNav>
            </c.LayoutVertical>
          </>)
        }
      }, {
        id: SCREEN_HOME_PROFILE,
        component: ({ context }) => {
          const r = context.react;
          const s = context.device.getScreen();
          const c = context.components;

          const [organizationName, setOrganizationName] = r.useState<string | null>(null)

          const [deviceUuid, setDeviceUuid] = r.useState<string | null>(null)
          const [deviceSerial, setDeviceSerial] = r.useState<string | null>(null)
          const [deviceImei0, setDeviceImei0] = r.useState<string | null>(null)
          const [deviceImei1, setDeviceImei1] = r.useState<string | null>(null)
          const [deviceLastSync, setDeviceLastSync] = r.useState<Date | null>(null)
          const [deviceVersionAPK, setDeviceVersionAPK] = r.useState<string | null>(null)
          const [deviceVersionAPP, setDeviceVersionAPP] = r.useState<string | null>(null)

          const [userUuid, setUserUuid] = r.useState<string | null>(null)
          const [userIdNumber, setUserIdNumber] = r.useState<string | null>(null)
          const [userFirstName, setUserFirstName] = r.useState<string | null>(null)
          const [userLastName, setUserLastName] = r.useState<string | null>(null)

          const [showStartAlert, setShowStartAlert] = r.useState(false)

          async function fetchOrganizationName() {
            const name = await context.organization.getOrganizationName()
            setOrganizationName(name)
          }

          async function fetchDeviceInfo() {
            const device = await context.device.getDevice()
            setDeviceUuid(device?.uuid ?? null)
            setDeviceSerial(device?.serial ?? null)
            setDeviceImei0(device?.imei0 ?? null)
            setDeviceImei1(device?.imei1 ?? null)

            const lastSync = await context.device.getLastSync()
            setDeviceLastSync(lastSync ?? null)

            const versionAPK = await context.device.getVersionAPK()
            setDeviceVersionAPK(versionAPK ?? null)

            const versionAPP = await context.device.getVersionAPP()
            setDeviceVersionAPP(versionAPP ?? null)
          }

          async function fetchUserInfo() {
            const user = await context.device.getUser()
            setUserUuid(user?.uuid ?? null)
            setUserIdNumber(user?.idNumber ?? null)
            setUserFirstName(user?.firstName ?? null)
            setUserLastName(user?.lastName ?? null)
          }

          // Initialize
          r.useEffect(() => {
            fetchOrganizationName()
            fetchDeviceInfo()
            fetchUserInfo()
          }, [])

          const updateState = async () => {
            const state = await context.protocolInstance.getLocalState() as unknown as ProtocolInstanceState | null
            if (!state) return;
            setShowStartAlert(state.id === 'state-patrol' && state.data.showStartAlert)
          }

          // Update Protocol Instance State
          r.useEffect(() => { updateState() }, [context.protocolInstance])

          return (<>
            <c.LayoutVertical>
              {/* Modal for alerting user of patrol that is starting */}
              <c.Modal isOpen={showStartAlert} onClose={() => { }}>
                <c.LayoutVertical>
                  <c.Text className='text-center text-black font-bold' variant='h4'>Start Patrol</c.Text>
                  <c.Text className='py-4 text-center text-black'> Patrol sequence has started, please scan the next beacon and follow the instructions on the status bar. </c.Text>
                  <c.LayoutVertical className='p-1' />
                  <c.Button onPress={async () => {
                    await onCloseStartAlert(context)
                    updateState()
                  }} variant='btn-primary' className='text-white' text="OK" />
                </c.LayoutVertical>
              </c.Modal>

              <c.Text variant='h3' className='p-4 text-white'>Profile</c.Text>

              <c.LayoutVertical className='flex-1 overflow-y-auto px-4'>
                <c.Text variant='h5' className='text-white'>Organization Information</c.Text>
                <c.LayoutHorizontal className='mt-1'>
                  <c.Text className="text-gray-400 mr-2">Name: </c.Text>
                  <c.Text className='text-white'>{organizationName}</c.Text>
                </c.LayoutHorizontal>


                <c.Text variant='h5' className='mt-4 text-white'>User Information</c.Text>
                <c.LayoutHorizontal className='mt-1'>
                  <c.Text className="text-gray-400 mr-2">UUID: </c.Text>
                  <c.Text className='text-white'>{userUuid}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">ID Number: </c.Text>
                  <c.Text className='text-white'>{userIdNumber}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">First Name: </c.Text>
                  <c.Text className='text-white'>{userFirstName}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">Last Name: </c.Text>
                  <c.Text className='text-white'>{userLastName}</c.Text>
                </c.LayoutHorizontal>


                <c.Text variant='h5' className='mt-4 text-white'>Device Information</c.Text>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">Device UUID: </c.Text>
                  <c.Text className='text-white'>{deviceUuid}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">Serial Number: </c.Text>
                  <c.Text className='text-white'>{deviceSerial}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">IMEI 0: </c.Text>
                  <c.Text className='text-white'>{deviceImei0}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">IMEI 1: </c.Text>
                  <c.Text className='text-white'>{deviceImei1}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">Version APK: </c.Text>
                  <c.Text className='text-white'>{deviceVersionAPK}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">Version APP: </c.Text>
                  <c.Text className='text-white'>{deviceVersionAPP}</c.Text>
                </c.LayoutHorizontal>
                <c.LayoutHorizontal>
                  <c.Text className="text-gray-400 mr-2">Last Sync: </c.Text>
                  <c.Text className='text-white'>{deviceLastSync ? deviceLastSync.toLocaleString() : 'Never'}</c.Text>
                </c.LayoutHorizontal>
              </c.LayoutVertical>

              <c.LayoutHorizontal className='p-4'>
                <c.Button variant='btn-neutral' className="w-full text-white py-4 rounded-xl"
                  onPress={async () => {
                    await context.device.signOutUser()
                    context.device.setScreen(null)
                  }} text='User Logout' />
              </c.LayoutHorizontal>

              {/* Bottom Nav */}
              <c.BottomNav>
                <c.BottomNavItem icon={<c.Icon icon='home' className="w-6 h-6" />} text="Map"
                  isActive={s?.id === SCREEN_HOME_MAP}
                  onPress={() => {
                    context.device.setScreen({ id: SCREEN_HOME_MAP })
                  }} />
                <c.BottomNavItem icon={<c.Icon icon='user' className="w-6 h-6" />} text="Profile"
                  isActive={s?.id === SCREEN_HOME_PROFILE}
                  onPress={() => {
                    context.device.setScreen({ id: SCREEN_HOME_PROFILE })
                  }} />
              </c.BottomNav>
            </c.LayoutVertical>
          </>)
        }
      }],
    },
    device: {
      tick: async (context) => {
        const now = new Date();
        const logs = await context.protocolInstance.getLogs();
        const user = await context.device.getUser();
        const members = await context.protocolInstance.getMembers();
        const currentScreen = context.device.getScreen();

        async function noBeaconsLog(uuidSite: string) {
          const lastLogNoBeacons = logs.filter((log) => log.tags.includes(TAG_NO_BEACONS) && log.data.uuidSite === uuidSite).pop()
          if (!lastLogNoBeacons || now > new Date(lastLogNoBeacons.datetime.valueOf() + 10 * 60 * 1000)) {
            const site = await context.organization.getSite(uuidSite)
            if (!site) { console.error("Site not found"); return; }
            await context.protocolInstance.createLog(`No beacons found for site: ${site.name}`, { uuidSite }, [TAG_NO_BEACONS])
          }
        }

        async function promptUserSignIn() {
          const lastLogUserAcknowledgedSignIn = logs.filter((log) => log.tags.includes(TAG_USER_ACKNOWLEDGED_SIGN_IN)).pop()
          // If the user acknowledged the sign in alert, and it was less than 5 minutes ago, skip
          if (lastLogUserAcknowledgedSignIn && now < new Date(lastLogUserAcknowledgedSignIn.datetime.valueOf() + 5 * 60 * 1000)) {
            return;
          }

          const audio = context.device.getAudio()
          if (audio?.id !== SOUND_SIGN_IN_ALERT || audio?.loop === 0) {
            await context.protocolInstance.createLog("Sign in alert", {}, [TAG_SIGN_IN_ALERT])
            context.device.setAudio({ id: SOUND_SIGN_IN_ALERT, base64Audio: context.vigil.audioBase64.alert_1, loop: 50 });
          }

          // If there is no modal, or a different model, show modal
          if (!currentScreen || currentScreen.id != SCREEN_SIGN_IN_REQUIRED) {
            context.device.setScreen({ id: SCREEN_SIGN_IN_REQUIRED });
          }
        }

        // If user is not signed in, show sign in modal
        if (!user) { await promptUserSignIn(); return; }

        // Take first site
        // TODO: Handle multiple sites
        const site = members.sites[0]
        const beacons = await context.organization.getBeaconsLinkedToSite(site.uuid)

        // If no beacons, log
        if (beacons.length === 0) { await noBeaconsLog(site.uuid); return; }

        // If screen is not set to the home profile screen, set it
        if (!currentScreen || currentScreen.id != SCREEN_HOME_MAP) {
          context.device.setScreen({ id: SCREEN_HOME_MAP })
        }

        // Handle state
        await onTick(context)
      },
      onPanic: async (context) => {
        const device = await context.device.getDevice();
        const user = await context.device.getUser();
        const userName = user ? `${user.firstName} ${user.lastName}` : "unknown"
        await context.protocolInstance.createLog(`Panic button pressed by user: ${userName} on device: ${device.uuid}`, { uuidDevice: device.uuid, uuidUser: user?.uuid ?? "unknown" }, [TAG_PANIC])
        context.device.setAudio({ id: SOUND_PANIC_ACKNOWLEDGED, base64Audio: context.vigil.audioBase64.success_3, loop: 1 })
      },
      onUserSignIn: async (context) => {
        const device = await context.device.getDevice();
        const user = context.signedInUser;
        const userName = user ? `${user.firstName} ${user.lastName}` : "unknown"
        await context.protocolInstance.createLog(`Sign in user: ${userName} on device: ${device.uuid}`, { uuidDevice: device.uuid, uuidUser: user?.uuid ?? "unknown" }, [TAG_USER_SIGN_IN])
      },
      onUserSignOut: async (context) => {
        const device = await context.device.getDevice();
        const user = context.signedOutUser;
        const userName = user ? `${user.firstName} ${user.lastName}` : "unknown"
        await context.protocolInstance.createLog(`Sign out user: ${userName} on device: ${device.uuid}`, { uuidDevice: device.uuid, uuidUser: user?.uuid ?? "unknown" }, [TAG_USER_SIGN_OUT])
      },
      onStart: async (context) => {
        // Sign the user out on start, so that we are 100% sure that we capture the info of the new user
        context.device.setScreen(null);
        context.device.setAudio(null);
        await context.device.signOutUser();
      },
      onEnd: async (context) => {
        context.device.setScreen(null);
        context.device.setAudio(null);
        await context.device.signOutUser();
      }
    },
    server: {
      tick: async (context) => {
        const now = new Date();
        const logs = await context.protocolInstance.getLogs();
        const members = await context.protocolInstance.getMembers()

        async function noSitesLinkedLog() {
          const lastLogNoSites = logs.filter((log) => log.tags.includes(TAG_NO_SITES_LINKED)).pop()
          if (!lastLogNoSites || now > new Date(lastLogNoSites.datetime.valueOf() + 10 * 60 * 1000)) {
            await context.protocolInstance.createLog("No sites linked to this protocol. You need to link at least one site to this protocol.", {}, [TAG_NO_SITES_LINKED])
          }
        }

        async function userNotSignedInLog(device: Device) {
          const lastLogSignInMissed = logs.filter((log) => log.tags.includes(TAG_SIGN_IN_MISSED_SERVER) && log.data.uuidDevice === device.uuid).pop()
          if (!lastLogSignInMissed || now > new Date(lastLogSignInMissed.datetime.valueOf() + 10 * 60 * 1000)) {
            await context.protocolInstance.createLog(`User not signed in on device: ${device.uuid}`, { uuidDevice: device.uuid }, [TAG_SIGN_IN_MISSED_SERVER])
          }
        }

        async function noBeaconsLog(uuidSite: string) {
          const lastLogNoBeacons = logs.filter((log) => log.tags.includes(TAG_NO_BEACONS) && log.data.uuidSite === uuidSite).pop()
          if (!lastLogNoBeacons || now > new Date(lastLogNoBeacons.datetime.valueOf() + 10 * 60 * 1000)) {
            const site = await context.organization.getSite(uuidSite)
            if (!site) { console.error("Site not found"); return; }
            await context.protocolInstance.createLog(`No beacons found for site: ${site.name}`, { uuidSite }, [TAG_NO_BEACONS])
          }
        }

        // If 10 minutes has not yet passed, skip
        if (now < new Date(context.protocolInstance.dateTimeStart.valueOf() + 10 * 60 * 1000)) return;

        // If no sites are linked
        if (members.sites.length === 0) { await noSitesLinkedLog(); return; }

        // If there are devices, for each device
        for (const deviceMember of members.devices) {
          // Get device SDK
          const deviceSDK = await context.organization.getSDKDevice(deviceMember.uuid)
          if (!deviceSDK) { console.error("Device SDK not found"); continue; }

          // Get user
          const user = await deviceSDK.getUser()

          // If user is null, create log
          if (!user) { await userNotSignedInLog(deviceMember); continue; }

          // If user is signed in, beacons linked to sites
          for (const siteMember of members.sites) {
            const beacons = await context.organization.getBeaconsLinkedToSite(siteMember.uuid)
            if (beacons.length === 0) { await noBeaconsLog(siteMember.uuid); continue; }
          }
        }
      },
      report: async (context) => {
        function generateRandomColors(count: number): string[] {
          const colors: string[] = [];
          const goldenRatio = 0.618033988749895;
          let hue = Math.random();

          for (let i = 0; i < count; i++) {
            // Use golden ratio to spread hues evenly
            hue += goldenRatio;
            hue %= 1;

            // Convert HSV to RGB with high saturation and value for distinguishable colors
            const h = hue * 360;
            const s = 0.7 + Math.random() * 0.2; // 70-90% saturation
            const v = 0.8 + Math.random() * 0.2; // 80-100% value

            // HSV to RGB conversion
            const c = v * s;
            const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
            const m = v - c;

            let r = 0, g = 0, b = 0;
            if (h < 60) { r = c; g = x; b = 0; }
            else if (h < 120) { r = x; g = c; b = 0; }
            else if (h < 180) { r = 0; g = c; b = x; }
            else if (h < 240) { r = 0; g = x; b = c; }
            else if (h < 300) { r = x; g = 0; b = c; }
            else { r = c; g = 0; b = x; }

            // Convert to RGB values
            const red = Math.round((r + m) * 255);
            const green = Math.round((g + m) * 255);
            const blue = Math.round((b + m) * 255);

            // Convert to hex
            const color = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
            colors.push(color);
          }

          return colors;
        }

        const { components: c, organization: o, protocolInstance: f } = context;
        const logs = await f.getLogs()
        const members = await f.getMembers()

        const panicLogs = logs.filter((log) => log.tags.includes(TAG_PANIC))
        const beaconMissedLogs = logs.filter((log) => log.tags.includes(TAG_MISSED_BEACON_SCAN))
        const beaconSuccessfulScans = logs.filter((log) => log.tags.includes(TAG_BEACON_SCANNED))
        const beaconFailedScans = logs.filter((log) => log.tags.includes(TAG_BEACON_SCAN_ERROR))
        const beaconTotalScans = logs.filter((log) => log.tags.includes(TAG_BEACON_SCANNED) || log.tags.includes(TAG_BEACON_SCAN_ERROR))
        const patrolUnsuccessfulLogs = logs.filter((log) => log.tags.includes(TAG_PATROL_SEQUENCE_FAILED))
        const patrolSuccessfulLogs = logs.filter((log) => log.tags.includes(TAG_PATROL_SEQUENCE_SUCCESS))
        const patrolTotalLogs = logs.filter((log) => log.tags.includes(TAG_PATROL_SEQUENCE_SUCCESS) || log.tags.includes(TAG_PATROL_SEQUENCE_FAILED))

        // TODO: Handle multiple sites
        const site = members.sites[0]
        const beacons = await o.getBeaconsLinkedToSite(site.uuid)
        const beaconColors = generateRandomColors(beacons.length)

        const markers = beacons.map((beacon, index) => {
          return {
            location: { lat: beacon.latitude, lng: beacon.longitude },
            color: beaconColors[index],
            label: beacon.name,
          }
        })

        // TODO: Need to change this to automatic
        const saFormatter = new Intl.DateTimeFormat('en-ZA', {
          timeZone: 'Africa/Johannesburg',
          dateStyle: 'short',
          timeStyle: 'medium'
        });

        const logsData = logs.map((log) => {
          const creator = log.creator.type === 'server' ? 'Server' : `Device (UUID): ${log.creator.uuid}`
          return {
            'Datetime': saFormatter.format(log.datetime),
            'Log Creator': creator,
            'Description': log.description
          }
        })

        return (
          <c.Document>
            <c.Page>
              <c.Heading1 text={f.protocolSnapshot.name} textAlign="center" marginTop={10} />
              <c.Heading3 text="Details" marginTop={10} textAlign="center" />
              <c.Table
                style={{ marginTop: 5 }}
                columns={['Description', 'Value']}
                columnWidths={{
                  'Description': 100,
                }}
                columnBehavior={{
                  'Description': 'fixed',
                  'Value': 'flexible',
                }}
                data={[
                  { 'Description': 'Sites Linked', 'Value': members.sites.map(s => s.name).join(', ') },
                  { 'Description': 'Devices Linked (UUID)', 'Value': members.devices.map(d => d.uuid).join(', ') },
                  { 'Description': 'Datetime Start', 'Value': saFormatter.format(f.dateTimeStart) },
                  { 'Description': 'Datetime End', 'Value': saFormatter.format(f.dateTimeEnd) },
                ]} />
              <c.Heading3 text="Summary" marginTop={10} textAlign="center" />
              <c.Table
                style={{ marginTop: 5 }}
                columns={['Description', 'Value']}
                columnWidths={{
                  'Description': 120,
                }}
                columnBehavior={{
                  'Description': 'fixed',
                  'Value': 'flexible',
                }}
                data={[
                  { 'Description': 'Panics Triggered', 'Value': panicLogs.length },
                  { 'Description': 'Beacons Scanned Total', 'Value': beaconTotalScans.length },
                  { 'Description': 'Successful Beacons Scans', 'Value': beaconSuccessfulScans.length },
                  { 'Description': 'Wrong Beacons Scanned', 'Value': beaconFailedScans.length },
                  { 'Description': 'Beacons Missed', 'Value': beaconMissedLogs.length },
                  { 'Description': 'Patrols Total', 'Value': patrolTotalLogs.length },
                  { 'Description': 'Patrols Successful', 'Value': patrolSuccessfulLogs.length },
                  { 'Description': 'Patrols Unsuccessful', 'Value': patrolUnsuccessfulLogs.length },
                ]} />
              {/* <c.Map
                title="Site 1 Street View"
                fitbounds={true}
                size={{ width: 500, height: 200 }}
                markers={markers}
                markersLegend={true}
                satellite={false}
                style={{ marginTop: 10 }}
              /> */}
              <c.Map
                title="Site 1 Satellite View"
                fitbounds={true}
                size={{ width: 500, height: 200 }}
                markers={markers}
                markersLegend={true}
                satellite={true}
                style={{ marginTop: 10 }}
              />
              <c.Heading3 break={true} text="Raw Protocol Logs" marginTop={10} textAlign="center" />
              <c.Table
                style={{ marginTop: 5 }}
                columns={['Datetime', 'Log Creator', 'Description']}
                columnWidths={{
                  'Datetime': 60,
                  'Log Creator': 140,
                }}
                columnBehavior={{
                  'Datetime': 'fixed',
                  'Log Creator': 'fixed',
                  'Description': 'flexible',
                }}
                data={logsData} />
            </c.Page>
          </c.Document>
        )
      }
    }
  }
})()