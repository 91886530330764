import jsQR from "jsqr";
import { VigilQRCode } from "./qrcode";
import { VQRBeacon } from "./qrcodes";
import { VigilQRCodes } from "./main";
import { uuidAllDecode } from "./decode";

export class VigilQRScanner {
  constructor(public readonly whitelist: VigilQRCode<any>[]) {
    // TODO: Check for duplicate IDs here
  }

  public scan(data: Uint8ClampedArray, width: number, height: number): VigilQRCode<any> | null {
    try {
      const result = jsQR(data, width, height);

      // Ensure result
      if (!result) {
        return null;
      }

      // Parse
      return this.parse(result.data);
    } catch (error) {
      // TODO: Check for old beacon QR UUID
      throw new Error('Scan failed.')
    }
  }

  public parse(text: string): VigilQRCode<any> | null {
    // Ensure it's json
    let json = null;
    try {
      json = JSON.parse(text)

      // Check for id
      const id = json['id'];

      // Check id type
      if (typeof id !== 'string') {
        return null;
      }

      // Check id valid
      for (const whitelisted of this.whitelist) {
        if (whitelisted.id === id) {
          return json;
        }
      }

      return null;
    } catch (error) {
      // Check if it's a beacon
      const prefix = 'http://vigl.me/';
      const prefixSecure = 'https://vigl.me/';
      const prefixNew = 'vigl.me/';
      if (text.startsWith(prefix) || text.startsWith(prefixSecure) || text.startsWith(prefixNew)) {
        const remaining = text.replace(prefix, '').replace(prefixSecure, '').replace(prefixNew, '');
        const uuid = remaining.split('/')[0];
        return {
          id: VigilQRCodes.beacon.id,
          uuid: uuidAllDecode(uuid)
        } as unknown as VigilQRCode<VQRBeacon>;
      }
      return null;
    }
  }
}