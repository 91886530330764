import { ProtocolConfig } from '../base';

export const ProtocolPanicAlert: ProtocolConfig = (() => {
  const TAG_SEND_PANIC_ALERT = "SEND_PANIC_ALERT";

  return {
    config: {
      id: "panic_alert",
      name: "Panic Alert",
      description: "Send a panic alert via Telegram to notify",
      triggers: [{ type: "schedule" }],
      parameters: [
        {
          id: "telegram_chat_ids",
          name: "Telegram Chat IDs",
          description: "List of Telegram chat IDs to send the alert to.\nYou can get a chat ID by sending /get_chat_id to @vigilsecure_bot on Telegram in the group or channel you want to send the alert to.",
          required: true,
          typing: {
            type: "array"
          },
        },
        {
          id: "panic_discard_duration_minutes",
          name: "Panic Discard Duration (HH:MM)",
          description: "If a panic is triggered by a device and the device only comes back online and syncs the panic and that duration from the actual panic and the synchronized panic is longer than this, then the panic is discarded. Note: 10m is the minimum allowed value.",
          required: true,
          // TODO: Add default value
          typing: {
            type: "duration"
          },
        },
      ],
      members: [],
      deviceScreens: [],
    },
    device: {
      tick: async () => { },
    },
    server: {
      tick: async (context) => {
        const now = new Date();
        const logs = await context.protocolInstance.getLogs();
        const parameters = context.protocolInstance.getParameters();
        const telegramChatIds = parameters.telegram_chat_ids as string[];
        const panicDiscardDurationMinutes = parameters.panic_discard_duration_minutes ? Math.max(parameters.panic_discard_duration_minutes, 10) : 10;

        const sdkDevices = await context.organization.getSDKDevices();
        for (const sdkDevice of sdkDevices) {
          const device = await sdkDevice.getDevice();

          const activityLogs = await sdkDevice.getActivityLogs(context.protocolInstance.dateTimeStart);
          const deviceActivityPanicAlertLogs = activityLogs.filter(activityLog =>
            activityLog.type === 'userPanic' && activityLog.datetime > new Date(now.getTime() - panicDiscardDurationMinutes * 60 * 1000)
          );
          const protocolInstancePanicLogs = logs.filter(log =>
            log.tags.includes(TAG_SEND_PANIC_ALERT) && log.datetime > new Date(now.getTime() - panicDiscardDurationMinutes * 60 * 1000)
          );

          for (const deviceActivityPanicAlertLog of deviceActivityPanicAlertLogs) {
            const { userUuid, userFirstName, userLastName, lat, lng, acc } = deviceActivityPanicAlertLog.data as { userUuid: string, userFirstName: string, userLastName: string, lat: number, lng: number, alt: number, acc: number };

            const protocolInstancePanicLogExists = protocolInstancePanicLogs.find(log => log.data.deviceActivityLogUuid === deviceActivityPanicAlertLog.uuid);

            if (!protocolInstancePanicLogExists) {
              await context.protocolInstance.createLog(
                `Sending panic alert to ${telegramChatIds.join(', ')} of device ${device.uuid} with signed in user ${userFirstName} ${userLastName} at Location: https://www.google.com/maps?q=${lat},${lng} (Accuracy: ${acc}m)`,
                {
                  deviceActivityLogUuid: deviceActivityPanicAlertLog.uuid,
                  telegramChatIds: telegramChatIds,
                  deviceUuid: device.uuid,
                  userUuid: userUuid,
                },
                [TAG_SEND_PANIC_ALERT]
              );
              await context.notification.sendTelegrams(
                telegramChatIds.map((chatId: string) => ({
                  type: 'MSG',
                  msg: `🚨 Device ${device.uuid} with signed in user ${userFirstName} ${userLastName} has triggered a panic alert.\n\n📍 Location: https://www.google.com/maps?q=${lat},${lng} (Accuracy: ${acc}m)`,
                  target: chatId,
                }))
              );
            }
          }
        }
      },
    },
  };
})(); 