import { Beacon } from 'vigil-protocol';
import { InputTagSelect, TagOption } from '../../../../components/input_tag_select';

interface BeaconsMembersProps {
  availableBeacons: Beacon[];
  selectedBeacons: Beacon[];
  onChange?: (beacons: Beacon[]) => void;
}

export const BeaconsMembers: React.FC<BeaconsMembersProps> = ({
  availableBeacons,
  selectedBeacons,
  onChange,
}) => {
  // Convert beacons to tag options
  const beaconOptions: TagOption[] = availableBeacons.map(beacon => ({
    label: `${beacon.name} (${beacon.type})`,
    value: beacon.uuid,
  }));

  const selectedTags: TagOption[] = selectedBeacons.map(beacon => ({
    label: `${beacon.name} (${beacon.type})`,
    value: beacon.uuid,
  }));

  const handleChange = (tags: TagOption[]): void => {
    // Convert selected tags back to Beacon objects
    const beacons = tags.map(tag => {
      const beacon = availableBeacons.find(b => b.uuid === tag.value);
      return beacon!;
    });
    onChange?.(beacons);
  };

  return (
    <div>
      <div className="font-bold text-sm">Beacons</div>
      <div className="italic text-sm">Select the beacons that should be linked to this protocol (order matters)</div>
      <InputTagSelect
        options={beaconOptions}
        selectedTags={selectedTags}
        onChange={handleChange}
        errors={[]}
        labelText=""
      />
    </div>
  );
};
