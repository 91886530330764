import { DurationState } from "./input_duration";
import { TimeState } from "./input_time";

export interface Occurance {
  MON: boolean;
  TUE: boolean;
  WED: boolean;
  THU: boolean;
  FRI: boolean;
  SAT: boolean;
  SUN: boolean;
}

function getTimeZoneOffset() {
  const now = new Date();
  return now.getTimezoneOffset() / 60;
}

function convertTo24Hour(hour: number, period: 'AM' | 'PM'): number {
  if (period === 'AM') return hour === 12 ? 0 : hour;
  return hour === 12 ? 12 : hour + 12;
}

function convertTo12Hour(hour: number): { hour12: number; period: 'AM' | 'PM' } {
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
  return { hour12, period };
}

export function getCronExpression(timeInstance: TimeState, occuranceInstance: Occurance) {
  const offset = getTimeZoneOffset();
  const hour24 = convertTo24Hour(timeInstance.hour, timeInstance.period);
  const adjustedHour = (hour24 + offset + 24) % 24;  // Ensure positive hour

  const days = Object.entries(occuranceInstance)
    .map(([day, isSelected], index) => isSelected ? (day === 'SUN' ? 0 : index + 1) : null)
    .filter((day): day is number => day !== null);

  return `${timeInstance.minute} ${adjustedHour} * * ${days.join(',')}`;
}

/**
 * Calculates the total duration in seconds based on the provided DurationState object.
 * If any of the duration properties (hours, minutes, seconds) is undefined, it defaults to 0.
 *
 * @param durationInstance - The DurationState object containing the duration properties.
 * @returns The total duration in seconds.
 */
export function getDuration(durationInstance: DurationState) {
  const hours = durationInstance.hours ?? 0;
  const minutes = durationInstance.minutes ?? 0;
  // const seconds = durationInstance.seconds ?? 0;
  // return hours * 3600 + minutes * 60 + seconds;
  return hours * 3600 + minutes * 60;
}

export function getStartTimeInStr(cronExpression: string) {
  const offset = getTimeZoneOffset();
  const [minuteStr, hourStr] = cronExpression.split(' ');
  const hour = (parseInt(hourStr) - offset + 24) % 24;  // Ensure positive hour
  const minute = parseInt(minuteStr);

  const { hour12, period } = convertTo12Hour(hour);
  return `${hour12.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`;
}

export function getOccuranceInStr(cronExpression: string) {
  const [, , , , daysStr] = cronExpression.split(' ');
  const days = daysStr.split(',').map(day => parseInt(day));
  const dayStr = days.map(day => {
    switch (day) {
      case 0: return 'SUN';
      case 1: return 'MON';
      case 2: return 'TUE';
      case 3: return 'WED';
      case 4: return 'THU';
      case 5: return 'FRI';
      case 6: return 'SAT';
    }
  });
  return dayStr.join(', ');
}

export function getDurationInStr(durationMS: number) {
  const milliseconds = durationMS % 1000;
  const seconds = Math.floor((durationMS / 1000) % 60);
  const minutes = Math.floor((durationMS / 1000 / 60) % 60);
  const hours = Math.floor((durationMS / 1000 / 60 / 60) % 24);
  const days = Math.floor(durationMS / 1000 / 60 / 60 / 24);

  const parts: string[] = [];

  if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);
  if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  if (seconds > 0) parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
  if (milliseconds > 0) parts.push(`${milliseconds} millisecond${milliseconds > 1 ? 's' : ''}`);

  return parts.join(', ');
}

export function getTimeState(cronExpression: string): TimeState {
  const offset = getTimeZoneOffset();
  const [minuteStr, hourStr] = cronExpression.split(' ');
  const hour = (parseInt(hourStr) - offset + 24) % 24;  // Ensure positive hour
  const minute = parseInt(minuteStr);

  const { hour12, period } = convertTo12Hour(hour);
  return {
    minute,
    hour: hour12,
    period,
  };
}

export function getOccurance(cronExpression: string) {
  const [, , , , daysStr] = cronExpression.split(' ');
  const days = daysStr.split(',').map(day => parseInt(day));
  return {
    MON: days.includes(1),
    TUE: days.includes(2),
    WED: days.includes(3),
    THU: days.includes(4),
    FRI: days.includes(5),
    SAT: days.includes(6),
    SUN: days.includes(0),
  };
}

/**
 * Calculates the duration state in hours, minutes, and seconds.
 * 
 * @param duration - The duration in seconds.
 * @returns An object containing the calculated hours, minutes, and seconds.
 */
export function getDurationState(duration: number, exclude?: ('hours' | 'minutes' | 'seconds')[]) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = duration - hours * 3600 - minutes * 60;
  const result: any = {};
  if (!exclude?.includes('hours')) result['hours'] = hours;
  if (!exclude?.includes('minutes')) result['minutes'] = minutes;
  if (!exclude?.includes('seconds')) result['seconds'] = seconds;
  return result;
}
