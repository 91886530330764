import { useEffect, useState } from 'react';
import { ProtocolParameter, ProtocolParameterTypeArray } from 'vigil-protocol';
import { InputTagSelect, TagOption } from '../../../../components/input_tag_select';
import { SDKOrganizationImpl } from '../../../../sdks/sdk_organization';

type ProtocolParameterArray = ProtocolParameter & { typing: ProtocolParameterTypeArray };

interface ProtocolParameterArrayInputProps {
  sdkOrganizationImpl: SDKOrganizationImpl;
  parameter: ProtocolParameterArray;
  value: Array<any>;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: Array<any>) => void;
}

export const ProtocolParameterArrayInput: React.FC<ProtocolParameterArrayInputProps> = ({
  sdkOrganizationImpl,
  parameter,
  value = [],
  onValidationErrors,
  onChange,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const arrayValidation = parameter.typing;
  const [options, setOptions] = useState<TagOption[]>([]);

  useEffect(() => {
    async function loadOptions() {
      if (arrayValidation.options) {
        try {
          const fetchedOptions = await arrayValidation.options(sdkOrganizationImpl);
          setOptions(fetchedOptions.map(opt => ({
            label: opt.label,
            value: typeof opt.value === 'boolean' ? opt.value.toString() : opt.value
          })));
        } catch (error) {
          console.error('Failed to load options:', error);
        }
      }
    }

    loadOptions();
  }, [sdkOrganizationImpl, arrayValidation.options]);

  const validateArray = (items: any[]): void => {
    const newErrors: string[] = [];

    if (parameter.required && items.length < 1) {
      newErrors.push('At least 1 item is required');
    }

    if (arrayValidation) {
      if (typeof arrayValidation.minItems === 'number' && items.length < arrayValidation.minItems) {
        newErrors.push(`Minimum ${arrayValidation.minItems} items required`);
      }
      if (typeof arrayValidation.maxItems === 'number' && items.length > arrayValidation.maxItems) {
        newErrors.push(`Maximum ${arrayValidation.maxItems} items allowed`);
      }
    }

    setErrors(newErrors);
    onValidationErrors?.(newErrors);
  };

  const selectedTags = (value || []).map(v => {
    if (arrayValidation.options) {
      const matchingOption = options.find(opt => opt.value === v);
      if (matchingOption) {
        return matchingOption;
      }
    }
    return {
      label: v?.toString() || '',
      value: v
    };
  });

  const handleChange = (tags: TagOption[]): void => {
    const values = tags.map(t => t.value);
    validateArray(values);
    onChange?.(values);
  };

  useEffect(() => {
    validateArray(value || []);
  }, [value]);

  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm">{parameter.description}</div>
      <InputTagSelect
        options={options}
        selectedTags={selectedTags}
        onChange={handleChange}
        allowCustomTags={!arrayValidation.options}
        errors={errors}
        labelText=""
      />
    </div>
  );
}; 