import { ProtocolConfig } from '../base';

export const ProtocolDeviceOfflineAlert: ProtocolConfig = (() => {
  const TAG_SEND_DEVICE_OFFLINE_ALERT = "SEND_DEVICE_OFFLINE_ALERT";

  return {
    config: {
      id: "device_offline_alert",
      name: "Device Offline Alert",
      description: "Send a device offline alert via Telegram to notify",
      triggers: [{ type: "schedule" }],
      parameters: [
        {
          id: "telegram_chat_ids",
          name: "Telegram Chat IDs",
          description: "List of Telegram chat IDs to send the alert to.\nYou can get a chat ID by sending /get_chat_id to @vigilsecure_bot on Telegram in the group or channel you want to send the alert to.",
          required: true,
          typing: {
            type: "array"
          },
        },
        {
          id: "device_offline_duration_minutes",
          name: "Device Offline Duration (HH:MM)",
          description: "For how long the device should be offline before sending the alert. Note: 10m is the minimum allowed value.",
          required: true,
          // TODO: Add default value
          typing: {
            type: "duration"
          },
        },
      ],
      members: [],
      deviceScreens: [],
    },
    device: {
      tick: async () => { },
    },
    server: {
      tick: async (context) => {
        const now = new Date();
        const logs = await context.protocolInstance.getLogs();
        const parameters = context.protocolInstance.getParameters();
        const telegramChatIds = parameters.telegram_chat_ids as string[];
        const deviceOfflineDurationMinutes = parameters.device_offline_duration_minutes ? Math.max(parameters.device_offline_duration_minutes, 10) : 10;

        const sdkDevices = await context.organization.getSDKDevices();
        for (const sdkDevice of sdkDevices) {
          const device = await sdkDevice.getDevice();

          const chargeState = await sdkDevice.getChargeState();
          if (!chargeState) continue;
          // Check if the device was offline for more than the device offline duration
          if (chargeState.datetime > new Date(now.getTime() - deviceOfflineDurationMinutes * 60 * 1000)) continue;

          // Check if a protocol instance log exists for the device offline alert created in the last deviceOfflineDuration minutes
          const deviceOfflineLogExists = logs.find(log =>
            log.tags.includes(TAG_SEND_DEVICE_OFFLINE_ALERT) &&
            log.data.deviceUuid === device.uuid &&
            log.datetime > new Date(now.getTime() - deviceOfflineDurationMinutes * 60 * 1000)
          );

          if (!deviceOfflineLogExists) {
            await context.protocolInstance.createLog(
              `Sending device offline alert to ${telegramChatIds.join(', ')} of device ${device.uuid} last online at ${chargeState.datetime.toISOString()}`,
              {
                deviceUuid: device.uuid,
                lastOnline: chargeState.datetime.getTime(),
              },
              [TAG_SEND_DEVICE_OFFLINE_ALERT]
            );
            await context.notification.sendTelegrams(
              telegramChatIds.map((chatId: string) => ({
                type: 'MSG',
                msg: `📵 Device ${device.uuid} has been offline for more than ${deviceOfflineDurationMinutes} minutes. Last online at ${chargeState.datetime.toISOString()}`,
                target: chatId,
              }))
            );
          }
        }
      },
    },
  };
})(); 