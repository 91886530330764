import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";
import { linkOrganizationDevices } from "./bean_link_organization_device";
import { linkOrganizationSites } from "./bean_link_organization_site";
import { linkOrganizationUsers } from "./bean_link_organization_user";
import { organizationRoles } from "./bean_organization_role";
import { linkOrganizationBeacons } from "./bean_link_organization_beacon";
import { notifications } from "./bean_notification";
import { protocols } from "./bean_protocol";

export const organizations = sqliteTable('organizations', {
  ...schemaBase(),
  name: text('name').unique().notNull(),
});

export const organizationsRelations = relations(organizations, ({ many }) => ({
  organizationsToUsers: many(linkOrganizationUsers),
  organizationsToDevices: many(linkOrganizationDevices),
  organizationsToSites: many(linkOrganizationSites),
  organizationsToBeacons: many(linkOrganizationBeacons),
  organizationRoles: many(organizationRoles),
  notifications: many(notifications),
  protocols: many(protocols),
}));
