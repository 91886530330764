import { FC, useContext } from 'react';
import { IDirectoryProtocolInstance, IProtocolSelect } from 'vigil-datamodel';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useTableData } from '../../hooks/use_table_data';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { ROUTES } from '../../router/routes';
import { NumberFilter } from '../../components/table/table_filters/table_filter_number';

interface ScreenHomeProtocolInstancesProps { }

export const ScreenHomeProtocolInstances: FC<ScreenHomeProtocolInstancesProps> = (props) => {
  const { protocolParent, fetchProtocolParent } = useOutletContext<{
    protocolParent: IProtocolSelect,
    fetchProtocolParent: () => void
  }>();
  
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const { data: protocolInstances, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryProtocolInstance>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryProtocolInstances({
        uuidOrganization: organization.data.uuid,
        filters: [...filters, { id: 'uuidProtocol', query: 'equals', value: protocolParent.uuid }],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateProtocolInstance(selectedProtocolInstance: IDirectoryProtocolInstance) {
    navigate(ROUTES.ROUTE_HOME_PROTOCOL_INSTANCE.replace(':uuid', protocolParent.uuid).replace(':instance_uuid', selectedProtocolInstance.uuid))
  }

  return (
    <div className='flex flex-col h-full'>
      <Table<IDirectoryProtocolInstance>
        className='h-full flex flex-col drop-shadow my-2'
        name='Protocol Instances'
        data={protocolInstances}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          // TODO: Fix sortability of nested fields
          {
            id: 'dateTimeStart',
            header: 'Date Start',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateProtocolInstance(item)}>
                  {new Date(item.dateTimeStart).toLocaleDateString() + " " + new Date(item.dateTimeStart).toLocaleTimeString()}
                </div>
              );
            },
          },
          {
            id: 'dateTimeEnd',
            header: 'Date End',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateProtocolInstance(item)}>
                  {new Date(item.dateTimeEnd).toLocaleDateString() + " " + new Date(item.dateTimeEnd).toLocaleTimeString()}
                </div>
              );
            },
          },
          {
            id: 'protocolSnapshot.version',
            header: 'Protocol Version',
            sortable: false,
            value(item) {
              return <div>{item.protocolSnapshot.version}.0</div>;
            },
          },
          {
            id: 'protocolSnapshot.parametersLabel',
            header: 'Protocol Parameters',
            sortable: false,
            value(item) {
              return (
                <div>
                  {item.protocolSnapshot.parametersLabel.split('; ').map((param, index) => {
                    const [key, value] = param.split(': ');
                    return (
                      <span key={index}>
                        <span className="font-semibold">{key}</span>: <span className="italic">{value}</span>
                        {index < item.protocolSnapshot.parametersLabel.split('; ').length - 1 ? '; ' : ''}
                      </span>
                    );
                  })}
                </div>
              );
            },
          },
        ]}

        filtersOptions={[
          new DateFilter('dateTimeStart', 'Date Started', 'between'),
          new DateFilter('dateTimeEnd', 'Date Ended', 'between'),
          new NumberFilter('protocolSnapshot.version', 'Protocol Version', 'between'),
          // TODO: Need to fix this filter, it does not work
          new TextFilter('protocolSnapshot.parametersLabel', 'Parameters', 'contains'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
}; 