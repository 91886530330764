import { useState } from "react";
import { InputButton } from "../../input_button";
import { BaseFilter, NumberQueries, QueryFilter } from "./table_filter_types";

export class NumberFilter<T> implements BaseFilter {
  id: string;
  name: string;
  query: NumberQueries;

  constructor(id: keyof T, name: string, query: NumberQueries) {
    this.id = id as string;
    this.name = name;
    this.query = query;
  }
}

export function TableNumberFilterModal<T>(props: { 
  numberFilter: NumberFilter<T>, 
  onClose: () => void, 
  onSubmit: (queryFilter: QueryFilter<T>) => void 
}) {
  const isBetween = props.numberFilter.query === "between";
  
  const [value, setValue] = useState<string | { start: string; end: string }>(
    isBetween ? { start: "", end: "" } : ""
  );

  const handleSingleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue((prev) => ({ 
      ...(prev as { start: string; end: string }), 
      start: e.target.value
    }));
  };

  const handleEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue((prev) => ({ 
      ...(prev as { start: string; end: string }), 
      end: e.target.value
    }));
  };

  let disabled = false;

  // For queries that require a value (excluding isNull / isNotNull), disable if value is not provided
  if (props.numberFilter.query !== "isNull" && props.numberFilter.query !== "isNotNull") {
    if (isBetween) {
      const currentVal = value as { start: string; end: string };
      disabled = currentVal.start === "" || currentVal.end === "";
    } else {
      disabled = value === "";
    }
  }

  const handleSubmit = () => {
    if (isBetween) {
      const currentVal = value as { start: string; end: string };
      props.onSubmit({
        id: props.numberFilter.id as any,
        query: props.numberFilter.query,
        value: [Number(currentVal.start), Number(currentVal.end)]
      });
    } else {
      props.onSubmit({
        id: props.numberFilter.id as any,
        query: props.numberFilter.query,
        value: Number(value as string)
      });
    }
  };

  return (
    <div className="card card-compact">
      <div className="card-body bg-base-100 flex flex-col rounded-xl">
        <div className="flex justify-between items-center">
          <div className="text-lg">Filter '{props.numberFilter.name}'</div>
          <button className="btn btn-sm btn-circle btn-ghost" onClick={props.onClose}>✕</button>
        </div>
        {isBetween ? (
          <>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Start Number</span>
              </label>
              <input
                type="number"
                className="input input-bordered"
                value={(value as { start: string; end: string }).start}
                onChange={handleStartChange}
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">End Number</span>
              </label>
              <input
                type="number"
                className="input input-bordered"
                value={(value as { start: string; end: string }).end}
                onChange={handleEndChange}
              />
            </div>
          </>
        ) : (
          <div className="form-control">
            <label className="label">
              <span className="label-text">Number</span>
            </label>
            <input
              type="number"
              className="input input-bordered"
              value={value as string}
              onChange={handleSingleValueChange}
            />
          </div>
        )}
        <div className="flex justify-end items-center mt-4">
          <InputButton
            text="Apply"
            loading={false}
            disabled={disabled}
            type="btn-primary"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
} 