import { useState } from 'react';
import { ProtocolParameter, ProtocolParamaterTypeCron } from 'vigil-protocol';
import { InputCron } from '../../../../components/input_cron';

type ProtocolParameterCron = ProtocolParameter & { typing: ProtocolParamaterTypeCron };

interface ProtocolParameterCronInputProps {
  parameter: ProtocolParameterCron;
  value: string;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: string) => void;
}

export const ProtocolParameterCronInput: React.FC<ProtocolParameterCronInputProps> = ({
  parameter,
  value,
  onValidationErrors,
  onChange,
}) => {
  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (cron: string): void => {
    onChange?.(cron);
  };

  const handleValidationErrors = (errors: string[]): void => {
    setErrors(errors);
    onValidationErrors?.(errors);
  };

  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm mb-1">{parameter.description}</div>
      <InputCron
        cron={value}
        onValidationErrors={handleValidationErrors}
        onChangeCron={handleChange}
      />
    </div>
  );
}; 