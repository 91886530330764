import React, { useContext, useEffect, useState } from 'react';
import { useModal } from '../../hooks/use_modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { InputButton } from '../../components/input_button';
import { IconPencilSquareSolid, IconTrashSolid } from '../../components/icons';
import { ROUTES } from '../../router/routes';
import { IProtocolSelect, IProtocolTrigger } from 'vigil-datamodel';
import { InputText } from '../../components/input_text';
import { VALIDATORS, validate } from '../../validation';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { ModalDeleteMany } from './modal_delete_many';
import { ModalUpdateOne } from './modal_update_one';
import { CRON, VIGIL_PROTOCOLS, Beacon, Device, Site, User, ProtocolParamaterTypeCron, ProtocolParamaterTypeDuration, ProtocolParameterTypeArray, ProtocolParameterTypeBoolean, ProtocolParameterTypeFloat, ProtocolParameterTypeInteger, ProtocolParameterTypeString, Protocol } from 'vigil-protocol';
import { FullPageLoader } from '../../components/full_page_loader';
import { ProtocolParameterStringInput, ProtocolParameterCronInput, ProtocolParameterIntegerInput, ProtocolParameterFloatInput, ProtocolParameterBooleanInput, ProtocolParameterArrayInput, ProtocolParameterDurationInput, ProtocolParameterOptionInput } from './func_protocol/parameters';
import { UsersMembers, SitesMembers, DevicesMembers, BeaconsMembers, ProtocolsMembers } from './func_protocol/members';
import { ScheduleTrigger } from './func_protocol/triggers';
import { FullPageError } from '../../components/full_page_error';
import { SDKOrganizationImpl } from '../../sdks/sdk_organization';

interface ScreenHomeProtocolOverviewProps { }

export const ScreenHomeProtocolOverview: React.FC<ScreenHomeProtocolOverviewProps> = (props) => {
  const { protocolParent, fetchProtocolParent } = useOutletContext<{
    protocolParent: IProtocolSelect,
    fetchProtocolParent: () => void
  }>();
  const protocol = VIGIL_PROTOCOLS.find(f => f.config.id === protocolParent.id);

  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const navigate = useNavigate();

  const [sdkOrganizationImpl, setSdkOrganizationImpl] = useState<SDKOrganizationImpl | null>(null);

  // Form state
  const [name, setName] = useState<string>(protocolParent.name);

  // Parameters state
  const [parameterValues, setParameterValues] = useState<Record<string, any>>(protocolParent.parameterValues);
  const [parameterErrors, setParameterErrors] = useState<Record<string, string[]>>({});

  // Triggers state
  const [triggers, setTriggers] = useState<IProtocolTrigger[]>(protocolParent.triggers);
  const [triggerErrors, setTriggerErrors] = useState<string[]>([]);

  // Members state
  const [selectedMembers, setSelectedMembers] = useState<Record<string, string[]>>({});
  const [memberErrors, setMemberErrors] = useState<Record<string, string[]>>({});

  // Available members state
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [availableSites, setAvailableSites] = useState<Site[]>([]);
  const [availableDevices, setAvailableDevices] = useState<Device[]>([]);
  const [availableBeacons, setAvailableBeacons] = useState<Beacon[]>([]);
  const [availableProtocols, setAvailableProtocols] = useState<Protocol[]>([]);
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);

  const { isOpen: isOpenModalDeleteProtocol, toggle: toggleModalDeleteProtocol } = useModal();
  const { isOpen: isOpenModalUpdateProtocol, toggle: toggleModalUpdateProtocol } = useModal();

  /* Fetch Members */
  useEffect(() => {
    const fetchMembers = async () => {
      if (!organization.data) return;

      setIsLoadingMembers(true);
      try {
        // Fetch all member types in parallel
        const [users, sites, devices, beacons, protocols] = await Promise.all([
          vigil.functions.findManyUsers({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManySites({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManyDevices({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManyBeacons({ uuidOrganization: organization.data.uuid }),
          vigil.functions.findManyProtocols({ uuidOrganization: organization.data.uuid })
        ]);

        setSelectedMembers({
          users: protocolParent.uuidMembers.filter(uuid => users.some(u => u.uuid === uuid)),
          sites: protocolParent.uuidMembers.filter(uuid => sites.some(s => s.uuid === uuid)),
          devices: protocolParent.uuidMembers.filter(uuid => devices.some(d => d.uuid === uuid)),
          beacons: protocolParent.uuidMembers.filter(uuid => beacons.some(b => b.uuid === uuid))
        });
        setAvailableUsers(users.map(u => ({ ...u, idNumber: u.idNumber || '', email: u.email || '', mobile: u.mobile || '' })));
        setAvailableSites(sites);
        setAvailableDevices(devices.map(d => ({ ...d, imei1: d.imei1 || '' })));
        setAvailableBeacons(beacons);
        setAvailableProtocols(protocols.map(f => ({ ...f, triggers: f.triggers as any, config: "TODO: Need to serialize protocol config" as any })));
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setIsLoadingMembers(false);
      }
    };

    fetchMembers();
  }, [organization.data]);

  useEffect(() => {
    if (!organization.data) return;
    setSdkOrganizationImpl(new SDKOrganizationImpl(organization.data, vigil));
  }, [organization.data]);

  /* Validation */
  const validateName = () => {
    if (!name) return [];
    return validate(name, [VALIDATORS.length('Protocol name', 2, 30)]);
  };

  const validateForm = () => {
    const errors: string[] = [];

    // Name validation
    if (!name) errors.push('Protocol name is required');
    if (validateName().length > 0) errors.push('Invalid protocol name');

    // Parameters validation
    if (Object.values(parameterErrors).some(errs => errs.length > 0)) {
      errors.push(...Object.values(parameterErrors).flat());
    }

    // Required parameters validation
    protocol?.config.parameters.forEach(param => {
      if (param.required && !parameterValues[param.id]) {
        errors.push(`Parameter ${param.name} is required`);
      }
    });

    // Triggers validation
    if (triggerErrors.length > 0) {
      errors.push(...triggerErrors);
    }

    // Members validation
    if (Object.values(memberErrors).some(errs => errs.length > 0)) {
      errors.push('Invalid member selection');
    }

    // Check if there is a difference between the new protocol values and the old protocol values
    const hasChanges =
      protocolParent.name !== name ||
      JSON.stringify(protocolParent.triggers) !== JSON.stringify(triggers) ||
      JSON.stringify(protocolParent.parameterValues) !== JSON.stringify(parameterValues) ||
      // TODO: We need to get this from a .env or something, not like this
      JSON.stringify(protocolParent.uuidMembers.filter(uuid => uuid !== '00000000000000_0000000')) !== JSON.stringify(Object.values(selectedMembers).flat());

    if (!hasChanges) {
      errors.push('No changes detected');
    }

    return errors;
  };

  /* Event Handlers */
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleParameterChange = (parameterId: string, value: any) => {
    setParameterValues(prev => ({
      ...prev,
      [parameterId]: value
    }));
  };

  const handleParameterValidationErrors = (parameterId: string, errors: string[]) => {
    setParameterErrors(prev => ({
      ...prev,
      [parameterId]: errors
    }));
  };

  const handleTriggerChange = (value: { cron?: string; durationMinutes?: number }) => {
    if (value.cron && typeof value.durationMinutes === 'number') {
      setTriggers([{
        type: "schedule",
        value: {
          cron: value.cron as CRON,
          durationMinutes: value.durationMinutes
        }
      }]);
    }
  };

  const handleTriggerValidationErrors = (errors: string[]) => {
    setTriggerErrors(errors);
  };

  const handleMemberChange = (memberType: string, uuids: string[]) => {
    setSelectedMembers(prev => ({
      ...prev,
      [memberType]: uuids
    }));
  };

  const handleMemberValidationErrors = (memberType: string, errors: string[]) => {
    setMemberErrors(prev => ({
      ...prev,
      [memberType]: errors
    }));
  };

  async function _deleteProtocol() {
    if (!organization.data) return;
    await vigil.functions.deleteProtocols({
      uuidOrganization: organization.data.uuid,
      uuids: [protocolParent.uuid]
    })
  }

  async function _updateProtocol() {
    if (!organization.data) return;
    await vigil.functions.updateProtocol({
      uuidOrganization: organization.data.uuid,
      uuid: protocolParent.uuid,
      data: {
        ...protocolParent,
        name: name,
        parameterValues: parameterValues,
        triggers: triggers,
        uuidMembers: Object.values(selectedMembers).flat()
      }
    });
  }

  if (!organization.data || !sdkOrganizationImpl || isLoadingMembers) return <FullPageLoader />;
  if (!protocol) return <FullPageError error='Protocol not found' />;

  const formErrors = validateForm();

  return (
    <div className='h-full w-full flex flex-col'>
      <ModalDeleteMany
        isOpen={isOpenModalDeleteProtocol}
        toggle={toggleModalDeleteProtocol}
        type='protocols'
        data={[{ uuid: protocolParent.uuid, label: protocolParent.name }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_PROTOCOLS)}
        deleteCallback={_deleteProtocol}
        extraComponents={
          <div>
            <ul className="list-disc ml-6">
              <li><strong>Terminate ALL active protocol instances related to this protocol</strong></li>
              <li>If a device that is running this protocol is offline, it will only be terminated when it comes back online</li>
            </ul>
          </div>
        }
      />
      <ModalUpdateOne
        isOpen={isOpenModalUpdateProtocol}
        toggle={toggleModalUpdateProtocol}
        updateCallback={_updateProtocol}
        onSubmit={async () => { fetchProtocolParent(); }}
        body={
          <div>
            <div className="mt-2">This will:</div>
            <ul className="list-disc ml-6">
              <li><strong>Terminate ALL active protocol instances related to this protocol</strong></li>
              <li>If a device that is running this protocol is offline, it will only be terminated when it comes back online</li>
              <li>Update the protocol instance with the new parameters</li>
              <li>Create a new protocol instance with the new parameters</li>
            </ul>
          </div>
        }
      />

      <div className='relative h-full mt-2'>
        <div className='absolute w-full h-full overflow-y-scroll'>

          <div className='rounded-t-xl bg-base-300 p-2'>
            <div className='flex items-center'>
              <div className='font-semibold flex flex-grow'>Overview</div>
              <InputButton
                text='Save'
                before={<IconPencilSquareSolid className='h-5 mr-1' />}
                type='btn-primary'
                size='btn-sm'
                disabled={formErrors.length > 0}
                onClick={toggleModalUpdateProtocol}
              />
              <div className='w-2'></div>
              <InputButton
                text='Delete'
                before={<IconTrashSolid className='h-5 mr-1' />}
                type='btn-error'
                size='btn-sm'
                loading={false}
                onClick={toggleModalDeleteProtocol}
              />
            </div>
          </div>

          <div className='rounded-b-xl bg-base-200 p-2 flex-grow'>
            <div className="card bg-base-300 shadow-sm mb-2 p-4">
              <div className="flex flex-wrap gap-4">
                <InputText
                  className="w-60 mb-2 mr-4"
                  labelText='Protocol name'
                  value={name}
                  onChange={handleNameChange}
                  errors={validateName()}
                />
                <div className="w-60 mb-2 mr-4">
                  <label className="label">
                    <span className="label-text">Protocol Type</span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    value={protocol?.config.name || ''}
                    disabled
                  />
                </div>
                <div className="w-60 mb-2 mr-4">
                  <label className="label">
                    <span className="label-text">Protocol Version: {protocolParent.version}.0</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="overflow-y-auto space-y-2">
              {/* Description Card */}
              <div className="card bg-base-300 shadow-sm p-4">
                <h4 className="font-bold text-m mb-2">Description</h4>
                <div className="text-sm">{protocol.config.description}</div>
              </div>

              {/* Triggers Card */}
              <div className="card bg-base-300 shadow-sm p-4">
                <h4 className="font-bold text-m mb-2">Trigger</h4>
                {triggers.map((trigger, index) => {
                  switch (trigger.type) {
                    case "schedule":
                      const showCron = protocol.config.triggers[index].value && protocol.config.triggers[index].value.cron ? false : true;
                      const showDuration = protocol.config.triggers[index].value && protocol.config.triggers[index].value.durationMinutes ? false : true;

                      return (
                        <ScheduleTrigger
                          key={trigger.type + index}
                          value={trigger.value as any}
                          onChange={handleTriggerChange}
                          onValidationErrors={handleTriggerValidationErrors}
                          showCron={showCron}
                          showDuration={showDuration}
                        />
                      )
                    default:
                      return null;
                  }
                })}
              </div>

              {/* Parameters Card */}
              {protocol.config.parameters.length > 0 && (
                <div className="card bg-base-300 shadow-sm p-4">
                  <h4 className="font-bold text-m mb-2">Parameters</h4>
                  <div className="space-y-4">
                    {Object.keys(parameterValues).map((parameterKey, index) => {
                      const parameterConfig = protocol.config.parameters[index];
                      const parameterType = parameterConfig.typing.type;
                      const parameterId = parameterConfig.id;

                      const value = parameterValues[parameterKey];

                      switch (parameterType) {
                        case 'string':
                          return (
                            <ProtocolParameterStringInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as ProtocolParameterTypeString }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'integer':
                          return (
                            <ProtocolParameterIntegerInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as ProtocolParameterTypeInteger }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'float':
                          return (
                            <ProtocolParameterFloatInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as ProtocolParameterTypeFloat }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'boolean':
                          return (
                            <ProtocolParameterBooleanInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as ProtocolParameterTypeBoolean }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'array':
                          return (
                            <ProtocolParameterArrayInput
                              key={parameterId}
                              sdkOrganizationImpl={sdkOrganizationImpl}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as ProtocolParameterTypeArray }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'duration':
                          return (
                            <ProtocolParameterDurationInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as ProtocolParamaterTypeDuration }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'cron':
                          return (
                            <ProtocolParameterCronInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as ProtocolParamaterTypeCron }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        case 'option':
                          return (
                            <ProtocolParameterOptionInput
                              key={parameterId}
                              parameter={{ ...parameterConfig, typing: parameterConfig.typing as any }}
                              value={value}
                              onChange={(v) => handleParameterChange(parameterId, v)}
                              onValidationErrors={(e) => handleParameterValidationErrors(parameterId, e)}
                            />
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>
                </div>
              )}

              {/* Members Card */}
              {protocol.config.members.length > 0 && (
                <div className="card bg-base-300 shadow-sm p-4">
                  <h4 className="font-bold text-m mb-2">Members</h4>
                  <div className="space-y-4">
                    {protocol.config.members.includes('users') && (
                      <UsersMembers
                        availableUsers={availableUsers}
                        selectedUsers={(selectedMembers['users']?.map(uuid => availableUsers.find(u => u.uuid === uuid)).filter(Boolean) || []) as User[]}
                        onChange={(users) => handleMemberChange('users', users.map(u => u.uuid))}
                      />
                    )}
                    {protocol.config.members.includes('sites') && (
                      <SitesMembers
                        availableSites={availableSites}
                        selectedSites={(selectedMembers['sites']?.map(uuid => availableSites.find(s => s.uuid === uuid)).filter(Boolean) || []) as Site[]}
                        onChange={(sites) => handleMemberChange('sites', sites.map(s => s.uuid))}
                      />
                    )}
                    {protocol.config.members.includes('devices') && (
                      <DevicesMembers
                        availableDevices={availableDevices}
                        selectedDevices={(selectedMembers['devices']?.map(uuid => availableDevices.find(d => d.uuid === uuid)).filter(Boolean) || []) as Device[]}
                        onChange={(devices) => handleMemberChange('devices', devices.map(d => d.uuid))}
                      />
                    )}
                    {protocol.config.members.includes('beacons') && (
                      <BeaconsMembers
                        availableBeacons={availableBeacons}
                        selectedBeacons={(selectedMembers['beacons']?.map(uuid => availableBeacons.find(b => b.uuid === uuid)).filter(Boolean) || []) as Beacon[]}
                        onChange={(beacons) => handleMemberChange('beacons', beacons.map(b => b.uuid))}
                      />
                    )}
                    {protocol.config.members.includes('protocols') && (
                      <ProtocolsMembers
                        availableProtocols={availableProtocols}
                        selectedProtocols={(selectedMembers['protocols']?.map(uuid => availableProtocols.find(f => f.uuid === uuid)).filter(Boolean) || []) as Protocol[]}
                        onChange={(protocols) => handleMemberChange('protocols', protocols.map(f => f.uuid))}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
