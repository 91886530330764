import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { IProtocolInstanceSelect } from 'vigil-datamodel';

interface ScreenHomeProtocolInstanceOverviewProps { }

export const ScreenHomeProtocolInstanceOverview: React.FC<ScreenHomeProtocolInstanceOverviewProps> = (props) => {
  const { protocolInstanceParent } = useOutletContext<{
    protocolInstanceParent: IProtocolInstanceSelect,
    fetchProtocolInstanceParent: () => void
  }>();

  const formatDateTime = (date: Date) => {
    return date.toLocaleString('en-US', {
      dateStyle: 'medium',
      timeStyle: 'medium'
    });
  };

  const formatLabel = (label: string) => {
    const eachLine = label.split(';');
    if (eachLine.length === 1) {
      return 'None';
    }
    return eachLine.map((line, index) => (
      <div key={index}>
        <span className="font-bold">{line.split(': ')[0]}:</span> <span className="italic">{line.split(': ')[1]}</span>
      </div>
    ));
  };

  return (
    <div className='h-full w-full flex flex-col'>
      <div className='relative h-full mt-2'>
        <div className='absolute w-full h-full'>
          <div className="card bg-base-200 shadow-sm p-2 space-y-2">
            {/* Timing Information */}
            <div className="bg-base-300 rounded-lg p-2">
              <h2 className="text-lg font-semibold mb-4">Execution Timeline</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="text-sm font-medium text-gray-500">Start Time</label>
                  <p className="mt-1">{formatDateTime(new Date(protocolInstanceParent.dateTimeStart))}</p>
                </div>
                <div>
                  <label className="text-sm font-medium text-gray-500">End Time</label>
                  <p className="mt-1">{formatDateTime(new Date(protocolInstanceParent.dateTimeEnd))}</p>
                </div>
              </div>
            </div>

            {/* Protocol Configuration */}
            <div className="bg-base-300 rounded-lg p-4">
              <h2 className="text-lg font-semibold mb-4">Protocol Configuration</h2>

              {/* Protocol Name */}
              <div className="mb-4">
                <label className="text-sm font-medium text-gray-500">Protocol Name</label>
                <div className="mt-1 text-sm ml-4">{protocolInstanceParent.protocolSnapshot.name}</div>
              </div>

              {/* Protocol ID */}
              <div className="mb-4">
                <label className="text-sm font-medium text-gray-500">Protocol ID</label>
                <div className="mt-1 text-sm ml-4">{protocolInstanceParent.protocolSnapshot.id}</div>
              </div>

              {/* Triggers */}
              <div className="mb-4">
                <label className="text-sm font-medium text-gray-500">Triggers</label>
                <div className="mt-1 text-sm ml-4">{formatLabel(protocolInstanceParent.protocolSnapshot.triggersLabel)}</div>
              </div>

              {/* Parameters */}
              <div className="mb-4">
                <label className="text-sm font-medium text-gray-500">Parameters</label>
                <div className="mt-1 text-sm ml-4">{formatLabel(protocolInstanceParent.protocolSnapshot.parametersLabel)}</div>
              </div>

              {/* Members */}
              <div>
                <label className="text-sm font-medium text-gray-500">Members</label>
                <div className="mt-1 text-sm ml-4">{formatLabel(protocolInstanceParent.protocolSnapshot.membersLabel)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
