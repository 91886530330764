import { ProtocolConfig } from "../base";

export const ProtocolDeviceAdoptSiteSpecificProtocols: ProtocolConfig = (() => {
  const TAG_NO_DEVICES_LINKED = "NO_DEVICES_LINKED"
  const TAG_NO_PROTOCOLS_LINKED = "NO_PROTOCOLS_LINKED"
  const TAG_NO_BEACONS_LINKED = "NO_BEACONS_LINKED"
  const TAG_ADOPTED_PROTOCOL = "ADOPTED_PROTOCOL"

  return {
    config: {
      id: "device_adopt_site_specific_protocols",
      name: "Device Adopt Site Specific Protocols",
      description: "Protocol that runs on a device to adopt site specific protocols. Note: If you make 'All Devices' true, it will override the 'Devices' parameter. If you do not select any devices, the protocol will not run.",
      triggers: [{ type: "schedule" }],
      parameters: [
        {
          id: "radius_from_beacons_meters",
          name: "Radius From Beacons (meters)",
          description: "The radius from the beacons to adopt the protocols",
          required: true,
          typing: {
            type: "integer",
            min: 10,
            max: 500,
            step: 10
          },
        },
        {
          id: "uuid_protocols",
          name: "Protocols",
          description: "The protocols to adopt",
          required: true,
          typing: {
            type: "array",
            options: async (organization) => {
              const protocols = await organization.getProtocols();
              // Can only adopt patrol protocols for now. Can implement other protocols later
              const filteredProtocols = protocols.filter(protocol => protocol.id === 'device_patrol')
              return filteredProtocols.map(protocol => ({ value: protocol.uuid, label: protocol.name }));
            }
          },
        }
      ],
      members: ['devices'],
      deviceScreens: [],
    },
    device: {
      tick: async (context) => {
        const now = new Date();
        const logs = await context.protocolInstance.getLogs();
        const parameters = context.protocolInstance.getParameters();
        const radiusFromBeaconsMeters = parameters.radius_from_beacons_meters;
        const uuidProtocols = parameters.uuid_protocols;

        async function noBeaconsInOrganizationLog() {
          const lastLogNoBeacons = logs.filter((log) => log.tags.includes(TAG_NO_BEACONS_LINKED)).pop()
          if (!lastLogNoBeacons || now > new Date(lastLogNoBeacons.datetime.valueOf() + 10 * 60 * 1000)) {
            await context.protocolInstance.createLog(`No beacons in organization. Please link beacons to sites to adopt protocols.`, {}, [TAG_NO_BEACONS_LINKED])
          }
        }

        // Extract required context objects
        const { organization, device, vigil } = context;

        // Exit if device has no location
        const deviceLocation = await device.getLocation();
        if (!deviceLocation) return;

        // Exit if organization has no beacons
        const beacons = await organization.getBeacons();
        if (beacons.length === 0) {
          await noBeaconsInOrganizationLog();
          return;
        }

        // Get beacons within configured radius, sorted by distance
        const sortedBeacons = beacons
          .filter(beacon => vigil.distance(beacon, deviceLocation) <= radiusFromBeaconsMeters)
          .sort((a, b) => vigil.distance(a, deviceLocation) - vigil.distance(b, deviceLocation));
        if (sortedBeacons.length === 0) return;

        // Process beacons in order of proximity
        let siteAlreadyChecked: string[] = []
        for (const beacon of sortedBeacons) {
          // Skip beacons with no linked sites
          const sites = await organization.getSitesLinkedToBeacon(beacon.uuid);
          if (sites.length === 0) continue;

          // Process sites linked to current beacon
          for (const site of sites) {
            if (siteAlreadyChecked.includes(site.uuid)) continue;
            siteAlreadyChecked.push(site.uuid)
            const protocols = await organization.getProtocolsLinkedToMember(site.uuid);
            for (const protocol of protocols) {
              if (!uuidProtocols.includes(protocol.uuid)) continue;
              const protocolInstances = await device.adoptProtocol(protocol, now)

              for (const protocolInstance of protocolInstances) {
                const logsExist = logs.filter(log => log.tags.includes(TAG_ADOPTED_PROTOCOL) && log.data.uuidProtocolInstance === protocolInstance.uuidProtocolInstance).pop()
                if (logsExist) continue;
                await context.protocolInstance.createLog(`Adopted protocol: ${protocol.name}`, { uuidProtocolInstance: protocolInstance.uuidProtocolInstance }, [TAG_ADOPTED_PROTOCOL]);
              }

              // This return is needed to prevent the protocol from adopting multiple protocols, so it will only adopt the nearest beacon's protocol
              return;
            }
          }
        }
      },
    },
    server: {
      tick: async (context) => {
        const now = new Date();
        const logs = await context.protocolInstance.getLogs();
        const parameters = context.protocolInstance.getParameters();
        const uuidProtocols = parameters.uuid_protocols as string[];
        const members = await context.protocolInstance.getMembers()

        async function noDevicesLinkedLog() {
          const lastLogNoDevices = logs.filter((log) => log.tags.includes(TAG_NO_DEVICES_LINKED)).pop()
          if (!lastLogNoDevices || now > new Date(lastLogNoDevices.datetime.valueOf() + 10 * 60 * 1000)) {
            await context.protocolInstance.createLog("No devices linked to this protocol. You need to link at least one device to this protocol.", {}, [TAG_NO_DEVICES_LINKED])
          }
        }

        async function noProtocolsLinkedLog() {
          const lastLogNoProtocols = logs.filter((log) => log.tags.includes(TAG_NO_PROTOCOLS_LINKED)).pop()
          if (!lastLogNoProtocols || now > new Date(lastLogNoProtocols.datetime.valueOf() + 10 * 60 * 1000)) {
            await context.protocolInstance.createLog("No protocols linked to this protocol. You need to link at least one protocol to this protocol.", {}, [TAG_NO_PROTOCOLS_LINKED])
          }
        }

        async function noBeaconsInOrganizationLog() {
          const lastLogNoBeacons = logs.filter((log) => log.tags.includes(TAG_NO_BEACONS_LINKED)).pop()
          if (!lastLogNoBeacons || now > new Date(lastLogNoBeacons.datetime.valueOf() + 10 * 60 * 1000)) {
            await context.protocolInstance.createLog(`No beacons in organization. Please link beacons to sites to adopt protocols.`, {}, [TAG_NO_BEACONS_LINKED])
          }
        }

        // Check if there are any beacons in the organization
        const beacons = await context.organization.getBeacons();
        if (beacons.length === 0) {
          await noBeaconsInOrganizationLog();
          return;
        }

        // If 10 minutes has not yet passed, skip
        if (now < new Date(context.protocolInstance.dateTimeStart.valueOf() + 10 * 60 * 1000)) return;

        // If no devices are linked
        if (members.devices.length === 0) await noDevicesLinkedLog()

        // If no protocols are linked
        if (uuidProtocols.length === 0) await noProtocolsLinkedLog()
      },
    }
  }
})()
