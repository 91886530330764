import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { organizations } from "./bean_organization";
import { devices } from "./bean_device";
import { relations } from "drizzle-orm";

export const linkOrganizationDevices = sqliteTable('link_organization_devices', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
}, (table) => ({
  organizationDeviceIdx: uniqueIndex('organization_device_idx').on(table.uuidOrganization, table.uuidDevice)
}));

export const linkOrganizationDevicesRelations = relations(linkOrganizationDevices, ({ one }) => ({
  organization: one(organizations, {
    fields: [linkOrganizationDevices.uuidOrganization],
    references: [organizations.uuid],
  }),
  device: one(devices, {
    fields: [linkOrganizationDevices.uuidDevice],
    references: [devices.uuid],
  }),
}));