import { useEffect, useState } from 'react';
import { ProtocolParameter, ProtocolParameterTypeFloat } from 'vigil-protocol';
import { InputText } from '../../../../components/input_text';

type ProtocolParameterFloat = ProtocolParameter & { typing: ProtocolParameterTypeFloat };

interface ProtocolParameterFloatInputProps {
  parameter: ProtocolParameterFloat;
  value: number;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: number) => void;
}

export const ProtocolParameterFloatInput: React.FC<ProtocolParameterFloatInputProps> = ({
  parameter,
  value,
  onValidationErrors,
  onChange,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const numberValidation = parameter.typing;

  const validateFloat = (value: number): void => {
    const newErrors: string[] = [];
    
    if (numberValidation) {
      if (typeof numberValidation.min === 'number' && value < numberValidation.min) {
        newErrors.push(`Minimum value is ${numberValidation.min}`);
      }
      if (typeof numberValidation.max === 'number' && value > numberValidation.max) {
        newErrors.push(`Maximum value is ${numberValidation.max}`);
      }
      if (numberValidation.step && (value % numberValidation.step) !== 0) {
        newErrors.push(`Must be in steps of ${numberValidation.step}`);
      }
    }
    
    setErrors(newErrors);
    onValidationErrors?.(newErrors);
  };

  useEffect(() => {
    validateFloat(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = parseFloat(event.target.value);
    if (!isNaN(newValue)) {
      validateFloat(newValue);
      onChange?.(newValue);
    }
  };

  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm">{parameter.description}</div>
      <InputText
        className="w-44"
        inputType="numberFloat"
        labelText=""
        value={value}
        onChange={handleChange}
        errors={errors}
      />
    </div>
  );
}; 