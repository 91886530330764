import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../router/routes';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { IProtocolSelect } from 'vigil-datamodel';
import { ContextOrganization } from '../../providers/provider_organization';
import { IconDocumentMagnifyingGlass, IconInboxStack, IconSquare3Stack } from '../../components/icons';
import { Tabs } from '../../components/tabs';

interface ScreenHomeProtocolProps { }

export const ScreenHomeProtocol: React.FC<ScreenHomeProtocolProps> = (props) => {
  const navigate = useNavigate();
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidProtocol = params['uuid'] || '';

  const [stateError, setError] = useState<string>('');
  const [stateProtocolLoading, setProtocolLoading] = useState(true);
  const [stateProtocol, setProtocol] = useState<IProtocolSelect>();

  useEffect(() => { fetchProtocol(); }, [uuidProtocol]);

  async function fetchProtocol() {
    try {
      if (!organization.data?.uuid) throw new Error('Organization not found');
      setProtocolLoading(true);
      const protocol = await vigil.functions.findOneProtocol({ uuidOrganization: organization.data.uuid, uuid: uuidProtocol });
      setProtocol(protocol);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setProtocolLoading(false);
    }
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  function handleTabSelect(id: string) {
    navigate(`${ROUTES.ROUTE_HOME_PROTOCOL.replace(':uuid', uuidProtocol)}/${id}`);
  }

  if (stateProtocolLoading || !stateProtocol) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full h-full flex flex-col'>
      <Breadcrumbs
        crumbs={[
          { id: 'protocols', onClick: () => navigate(ROUTES.ROUTE_HOME_PROTOCOLS), text: 'Protocols' },
          { id: uuidProtocol, onClick: () => { }, text: stateProtocol.name }
        ]}
      />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <Tabs selectedId={getSelectedId()} onSelect={handleTabSelect} items={[
        {
          icon: IconDocumentMagnifyingGlass,
          id: 'overview',
          text: 'Overview'
        },
        {
          icon: IconInboxStack,
          id: 'instances',
          text: 'Instances'
        },
        // TODO: Implement
        // {
        //   icon: IconSquare3Stack,
        //   id: 'raw_logs',
        //   text: 'Raw Logs'
        // }
      ]} />

      <div className='w-full flex-grow'>
        <Outlet context={{ protocolParent: stateProtocol, fetchProtocolParent: fetchProtocol }} />
      </div>
    </div>
  )
};
