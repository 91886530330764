import { ReactNode, createContext, useContext } from "react";
import { ContextVigilClient } from "./provider_vigil_client";
import { Preferences } from "../preference_keys";
import { FullPageLoader } from "../components/full_page_loader";
import { useCaller } from "../hooks/use_caller";
import { FullPageError } from "../components/full_page_error";
import { IGenericContext } from "./type_generic_context";
import { IOrganizationSelect } from "vigil-datamodel";
import { InputButton } from "../components/input_button";
import { StatusAlert } from "../components/status_alert";

export const ContextOrganization = createContext(null as any as IGenericContext<IOrganizationSelect>);

interface ProviderOrganizationProps {
  children: ReactNode
}
export const ProviderOrganization: React.FC<ProviderOrganizationProps> = (props: ProviderOrganizationProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);

  /* State */
  const organization = useCaller({
    callback: async () => {
      const selectedOrg = localStorage.getItem(Preferences.SELECTED_ORGANIZATION);
      if (!selectedOrg) return null;
      return await vigil.functions.findOneOrganization({ uuid: selectedOrg });
    },
    dependencies: { background: [vigil], normal: [] },
  });

  async function actionSignOut() {
    localStorage.removeItem(Preferences.TOKEN);
    localStorage.removeItem(Preferences.SELECTED_ORGANIZATION);
    await organization.call(false);
  }

  if (organization.loading) return <FullPageLoader location={"organization"} />
  if (organization.error) return (
    <div className="h-full flex flex-col items-center justify-center">
      <div className="flex">
        <StatusAlert message={organization.error} type='alert-error' />
      </div>
      <InputButton
        text="Reload Page"
        type="btn-primary"
        className="mt-4 max-w-xs"
        onClick={actionSignOut}
      />
    </div>
  )

  return (
    <ContextOrganization.Provider value={{
      data: organization.result,
      loading: organization.loading,
      error: organization.error,
      refresh: organization.call,
    }}>
      {props.children}
    </ContextOrganization.Provider>
  )
}
