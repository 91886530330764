import { ProtocolDeviceAdoptSiteSpecificProtocols } from "./protocol_device_adopt_site_specific_protocols";
import { ProtocolSendPublicReport } from "./protocol_send_public_report";
import { ProtocolDevicePatrol } from "./protocol_patrol";
// import { ProtocolTest } from "./protocol_test";
import { ProtocolPanicAlert } from "./protocol_panic_alert";
import { ProtocolDeviceOfflineAlert } from "./protocol_device_offline_alert";

export const VIGIL_PROTOCOLS = [
  ProtocolDeviceAdoptSiteSpecificProtocols,
  ProtocolDevicePatrol,
  ProtocolSendPublicReport,
  ProtocolPanicAlert,
  ProtocolDeviceOfflineAlert,
  // ProtocolTest
]