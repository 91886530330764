import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { protocolInstances } from "./bean_protocol_instance";
import { relations } from "drizzle-orm";

export enum ProtocolInstanceReportType {
	PDF = 'PDF',
	CSV = 'CSV',
	EXCEL = 'EXCEL',
	IMAGE = 'IMAGE',
	TEXT = 'TEXT',
	JSON = 'JSON',
	HTML = 'HTML',
}

export const protocolInstanceReports = sqliteTable('protocol_instance_reports', {
	...schemaBase(),
	uuidProtocolInstance: text('uuid_protocol_instance').notNull().references(() => protocolInstances.uuid),
	name: text('name').notNull(),
	base64: text('base64').notNull(),
	type: text('type', { enum: Object.values(ProtocolInstanceReportType) as [ProtocolInstanceReportType] }).notNull(),
});

export const protocolInstanceReportsRelations = relations(protocolInstanceReports, ({ one }) => ({
	protocolInstance: one(protocolInstances, {
		fields: [protocolInstanceReports.uuidProtocolInstance],
		references: [protocolInstances.uuid],
	}),
}));
