import { ProtocolParameter, ProtocolParameterTypeBoolean } from 'vigil-protocol';
import { Checkbox } from '../../../../components/checkbox';
import { InputBase } from '../../../../components/input_base';

type ProtocolParameterBoolean = ProtocolParameter & { typing: ProtocolParameterTypeBoolean };

interface ProtocolParameterBooleanInputProps {
  parameter: ProtocolParameterBoolean;
  value: boolean;
  onValidationErrors?: (errors: string[]) => void;
  onChange?: (value: boolean) => void;
}

export const ProtocolParameterBooleanInput: React.FC<ProtocolParameterBooleanInputProps> = ({
  parameter,
  value,
  onValidationErrors,
  onChange,
}) => {
  return (
    <div>
      <div className="font-bold text-sm">
        {parameter.name} {parameter.required && <span className="text-red-500">(Required)</span>}
      </div>
      <div className="italic text-sm mb-1">{parameter.description}</div>
      <InputBase labelText="" errors={[]}>
        <Checkbox
          label={parameter.name}
          checked={value}
          toggle={() => onChange?.(!value)}
        />
      </InputBase>
    </div>
  );
}; 