import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { protocolInstances } from "./bean_protocol_instance";
import { relations } from "drizzle-orm";

export enum ProtocolInstanceRunnerType {
  SERVER = 'SERVER',
  DEVICE = 'DEVICE',
}

export enum ProtocolInstanceRunnerStatus {
  NONE = 'NONE',
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
}

export const protocolInstanceRunners = sqliteTable('protocol_instance_runners', {
  ...schemaBase(),
  uuidProtocolInstance: text('uuid_protocol_instance').notNull().references(() => protocolInstances.uuid),
  uuidRunner: text('uuid_runner').notNull(),
  runnerType: text('runner_type', { enum: Object.values(ProtocolInstanceRunnerType) as [ProtocolInstanceRunnerType] }).notNull(),
  runnerStatus: text('runner_status', { enum: Object.values(ProtocolInstanceRunnerStatus) as [ProtocolInstanceRunnerStatus] }).notNull(),
  runnerState: customJsonColumn<Record<string, any>>('runner_state').notNull(),
}, (table) => ({
  protocolInstanceRunnerIdx: uniqueIndex('protocol_instance_runner_idx').on(table.uuidProtocolInstance, table.uuidRunner)
}));

export const protocolInstanceRunnersRelations = relations(protocolInstanceRunners, ({ one }) => ({
  protocolInstance: one(protocolInstances, {
    fields: [protocolInstanceRunners.uuidProtocolInstance],
    references: [protocolInstances.uuid],
  }),
}));