import React, { useState, useEffect } from 'react';
import { InputBase, InputBaseProps } from './input_base';
import parsePhoneNumber, { AsYouType, getCountries, getCountryCallingCode, CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

interface CountryOption {
  code: CountryCode;
  name: string;
  dialCode: string;
}

interface InputMobileProps extends InputBaseProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCountryChange?: (countryCode: CountryCode) => void;
  onFormattedValueChange?: (formattedValue: string) => void;
  placeholder?: string;
  disabled?: boolean;
  size?: "input-sm" | "input-md" | "input-lg";
  defaultCountry?: CountryCode;
}

export const InputMobile: React.FC<InputMobileProps> = (props) => {
  const [countryOptions, setCountryOptions] = useState<CountryOption[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(props.defaultCountry || 'US');
  const [formattedValue, setFormattedValue] = useState('');
  const [internationalValue, setInternationalValue] = useState('');

  // Initialize country options
  useEffect(() => {
    const countries = getCountries();
    const options = countries.map(code => ({
      code,
      name: new Intl.DisplayNames(['en'], { type: 'region' }).of(code) || code,
      dialCode: getCountryCallingCode(code)
    }));
    
    // Sort by country name
    options.sort((a, b) => a.name.localeCompare(b.name));
    setCountryOptions(options);
  }, []);

  // Format the phone number as the user types and generate international format
  useEffect(() => {
    if (props.value) {
      try {
        // Format the display value
        if (props.value.startsWith('+')) {
          const asYouType = new AsYouType();
          setFormattedValue(asYouType.input(props.value));
          
          // Try to detect the country from the phone number
          const phoneNumber = parsePhoneNumber(props.value);
          if (phoneNumber?.country) {
            setSelectedCountry(phoneNumber.country);
            if (props.onCountryChange) {
              props.onCountryChange(phoneNumber.country);
            }
          }
          
          // Set the international format
          setInternationalValue(props.value);
        } else {
          // Otherwise, format with the selected country
          const asYouType = new AsYouType(selectedCountry);
          setFormattedValue(asYouType.input(props.value));
          
          // Generate the international format
          const phoneNumber = parsePhoneNumberFromString(props.value, selectedCountry);
          if (phoneNumber && phoneNumber.isValid()) {
            const e164Format = phoneNumber.format('E.164');
            setInternationalValue(e164Format);
            
            // Notify parent component of the formatted value
            if (props.onFormattedValueChange) {
              props.onFormattedValueChange(e164Format);
            }
          } else {
            // Fallback for invalid numbers
            const numberWithoutLeadingZero = props.value.startsWith('0') 
              ? props.value.substring(1) 
              : props.value;
            
            const fallbackFormat = `+${getCountryCallingCode(selectedCountry)}${numberWithoutLeadingZero}`;
            setInternationalValue(fallbackFormat);
            
            // Notify parent component of the formatted value
            if (props.onFormattedValueChange) {
              props.onFormattedValueChange(fallbackFormat);
            }
          }
        }
      } catch (error) {
        // If formatting fails, just use the raw value
        setFormattedValue(props.value);
        
        // Fallback for international format
        if (!props.value.startsWith('+')) {
          const fallbackFormat = `+${getCountryCallingCode(selectedCountry)}${props.value}`;
          setInternationalValue(fallbackFormat);
          
          // Notify parent component of the formatted value
          if (props.onFormattedValueChange) {
            props.onFormattedValueChange(fallbackFormat);
          }
        } else {
          setInternationalValue(props.value);
          
          // Notify parent component of the formatted value
          if (props.onFormattedValueChange) {
            props.onFormattedValueChange(props.value);
          }
        }
      }
    } else {
      setFormattedValue('');
      setInternationalValue('');
      
      // Notify parent component of the empty value
      if (props.onFormattedValueChange) {
        props.onFormattedValueChange('');
      }
    }
  }, [props.value, selectedCountry]);

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCountry = e.target.value as CountryCode;
    setSelectedCountry(newCountry);
    if (props.onCountryChange) {
      props.onCountryChange(newCountry);
    }
    
    // Re-format the number with the new country code
    if (props.value && !props.value.startsWith('+')) {
      try {
        const phoneNumber = parsePhoneNumberFromString(props.value, newCountry);
        if (phoneNumber && phoneNumber.isValid()) {
          const e164Format = phoneNumber.format('E.164');
          setInternationalValue(e164Format);
          
          // Notify parent component of the formatted value
          if (props.onFormattedValueChange) {
            props.onFormattedValueChange(e164Format);
          }
        } else {
          // Fallback for invalid numbers
          const numberWithoutLeadingZero = props.value.startsWith('0') 
            ? props.value.substring(1) 
            : props.value;
          
          const fallbackFormat = `+${getCountryCallingCode(newCountry)}${numberWithoutLeadingZero}`;
          setInternationalValue(fallbackFormat);
          
          // Notify parent component of the formatted value
          if (props.onFormattedValueChange) {
            props.onFormattedValueChange(fallbackFormat);
          }
        }
      } catch (error) {
        // Fallback if parsing fails
        const fallbackFormat = `+${getCountryCallingCode(newCountry)}${props.value}`;
        setInternationalValue(fallbackFormat);
        
        // Notify parent component of the formatted value
        if (props.onFormattedValueChange) {
          props.onFormattedValueChange(fallbackFormat);
        }
      }
    }
  };

  // Handle input changes, ensuring only valid characters are accepted
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Allow only numbers, spaces, dashes, and plus signs
    const value = e.target.value;
    let sanitizedValue = value.replace(/[^\d\s\-+]/g, '');
    
    // Create a synthetic event with the sanitized value
    const syntheticEvent = {
      ...e,
      target: {
        ...e.target,
        value: sanitizedValue
      }
    } as React.ChangeEvent<HTMLInputElement>;
    
    props.onChange(syntheticEvent);
  };

  function styleErrors() {
    return props.errors.length ? 'input-error' : '';
  }

  return (
    <div>
      <InputBase {...props}>
        <div className={`input input-bordered flex items-center p-0 overflow-hidden ${styleErrors()} ${props.size || ''} ${props.disabled ? 'input-disabled' : ''}`}>
          <div className="bg-base-200 flex items-center h-full border-r border-base-300 px-2">
            <select 
              className="bg-base-200 border-none focus:outline-none py-2 h-full"
              value={selectedCountry}
              onChange={handleCountryChange}
              disabled={props.disabled}
            >
              {countryOptions.map(country => (
                <option key={country.code} value={country.code}>
                  {country.code} +{country.dialCode}
                </option>
              ))}
            </select>
          </div>
          <input 
            type="tel"
            inputMode="tel"
            placeholder={props.placeholder}
            className="grow bg-transparent px-3 py-2 h-full"
            onChange={handleInputChange}
            value={formattedValue}
            disabled={props.disabled}
          />
        </div>
      </InputBase>
    </div>
  );
}; 