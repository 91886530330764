import { schemaBase } from "./bean_base";

type BaseSchemaType = keyof ReturnType<typeof schemaBase>

export const schemaBaseKeys = Object.keys(schemaBase()) as (keyof ReturnType<typeof schemaBase>)[];

import { organizations } from "./bean_organization";
export type IOrganizationInsert = Omit<typeof organizations.$inferInsert, BaseSchemaType>;
export type IOrganizationUpdate = IOrganizationInsert;
export type IOrganizationSelect = typeof organizations.$inferSelect;

import { organizationRoles } from "./bean_organization_role";
export type IOrganizationRoleInsert = Omit<typeof organizationRoles.$inferInsert, BaseSchemaType>;
export type IOrganizationRoleUpdate = Omit<IOrganizationRoleInsert, 'mutable'>;
export type IOrganizationRoleSelect = typeof organizationRoles.$inferSelect;

import { organizationRoleUserInvite } from "./bean_organization_role_user_invite";
export type IOrganizationRoleUserInviteInsert = Omit<typeof organizationRoleUserInvite.$inferInsert, BaseSchemaType>;
export type IOrganizationRoleUserInviteUpdate = IOrganizationRoleUserInviteInsert;
export type IOrganizationRoleUserInviteSelect = typeof organizationRoleUserInvite.$inferSelect;

import { users } from "./bean_user";
export type IUserInsert = Omit<typeof users.$inferInsert, BaseSchemaType>;
export type IUserUpdate = IUserInsert;
export type IUserSelect = typeof users.$inferSelect;

import { sites } from "./bean_site";
export type ISiteInsert = Omit<typeof sites.$inferInsert, BaseSchemaType>;
export type ISiteUpdate = ISiteInsert;
export type ISiteSelect = typeof sites.$inferSelect;

import { devices } from "./bean_device";
export type IDeviceInsert = Omit<typeof devices.$inferInsert, BaseSchemaType>;
export type IDeviceUpdate = IDeviceInsert;
export type IDeviceSelect = typeof devices.$inferSelect;

import { beacons } from "./bean_beacon";
export type IBeaconInsert = Omit<typeof beacons.$inferInsert, Exclude<BaseSchemaType, 'uuid'>>;
export type IBeaconUpdate = Omit<IBeaconInsert, 'uuid'>;
export type IBeaconSelect = typeof beacons.$inferSelect;

import { notifications } from "./bean_notification";
export type INotificationInsert = Omit<typeof notifications.$inferInsert, BaseSchemaType>;
export type INotificationUpdate = INotificationInsert;
export type INotificationSelect = typeof notifications.$inferSelect;

import { notificationLogs } from "./bean_notification_log";
export type INotificationLogInsert = Omit<typeof notificationLogs.$inferInsert, BaseSchemaType>;
export type INotificationLogUpdate = INotificationLogInsert;
export type INotificationLogSelect = typeof notificationLogs.$inferSelect;

import { linkOrganizationUsers } from "./bean_link_organization_user";
export type ILinkOrganizationUserInsert = Omit<typeof linkOrganizationUsers.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationUserUpdate = ILinkOrganizationUserInsert;
export type ILinkOrganizationUserSelect = typeof linkOrganizationUsers.$inferSelect;

import { linkOrganizationSites } from "./bean_link_organization_site";
export type ILinkOrganizationSiteInsert = Omit<typeof linkOrganizationSites.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationSiteUpdate = ILinkOrganizationSiteInsert;
export type ILinkOrganizationSiteSelect = typeof linkOrganizationSites.$inferSelect;

import { linkOrganizationDevices } from "./bean_link_organization_device";
export type ILinkOrganizationDeviceInsert = Omit<typeof linkOrganizationDevices.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationDeviceUpdate = ILinkOrganizationDeviceInsert;
export type ILinkOrganizationDeviceSelect = typeof linkOrganizationDevices.$inferSelect;

import { linkOrganizationBeacons } from "./bean_link_organization_beacon";
export type ILinkOrganizationBeaconInsert = Omit<typeof linkOrganizationBeacons.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationBeaconUpdate = ILinkOrganizationBeaconInsert;
export type ILinkOrganizationBeaconSelect = typeof linkOrganizationBeacons.$inferSelect;

import { linkOrganizationRoleUser } from "./bean_link_organization_role_user";
export type ILinkOrganizationRoleUserInsert = Omit<typeof linkOrganizationRoleUser.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationRoleUserUpdate = ILinkOrganizationRoleUserInsert;
export type ILinkOrganizationRoleUserSelect = typeof linkOrganizationRoleUser.$inferSelect;

import { linkDeviceUserLoggedInStates } from "./bean_link_device_user_logged_in_state";
export type ILinkDeviceUserLoggedInStateInsert = Omit<typeof linkDeviceUserLoggedInStates.$inferInsert, BaseSchemaType>;
export type ILinkDeviceUserLoggedInStateUpdate = ILinkDeviceUserLoggedInStateInsert;
export type ILinkDeviceUserLoggedInStateSelect = typeof linkDeviceUserLoggedInStates.$inferSelect;

import { linkSiteBeacons } from "./bean_link_site_beacon";
export type ILinkSiteBeaconInsert = Omit<typeof linkSiteBeacons.$inferInsert, BaseSchemaType>;
export type ILinkSiteBeaconUpdate = ILinkSiteBeaconInsert;
export type ILinkSiteBeaconSelect = typeof linkSiteBeacons.$inferSelect;

import { dashSessions } from "./bean_dash_session";
export type IDashSessionInsert = Omit<typeof dashSessions.$inferInsert, BaseSchemaType>;
export type IDashSessionUpdate = IDashSessionInsert;
export type IDashSessionSelect = typeof dashSessions.$inferSelect;

import { dashActivityLogs } from "./bean_dash_activity_log";
export type IDashActivityLogInsert = Omit<typeof dashActivityLogs.$inferInsert, BaseSchemaType>;
export type IDashActivityLogUpdate = IDashActivityLogInsert;
export type IDashActivityLogSelect = typeof dashActivityLogs.$inferSelect;

import { dashStateLogs } from "./bean_dash_state_log";
export type IDashStateLogInsert = Omit<typeof dashStateLogs.$inferInsert, BaseSchemaType>;
export type IDashStateLogUpdate = IDashStateLogInsert;
export type IDashStateLogSelect = typeof dashStateLogs.$inferSelect;

import { deviceActivityLogs } from "./bean_device_activity_log";
export type IDeviceActivityLogInsert = Omit<typeof deviceActivityLogs.$inferInsert, BaseSchemaType>;
export type IDeviceActivityLogUpdate = IDeviceActivityLogInsert;
export type IDeviceActivityLogSelect = typeof deviceActivityLogs.$inferSelect;

import { deviceStateLogs } from "./bean_device_state_log";
export type IDeviceStateLogInsert = Omit<typeof deviceStateLogs.$inferInsert, BaseSchemaType>;
export type IDeviceStateLogUpdate = IDeviceStateLogInsert;
export type IDeviceStateLogSelect = typeof deviceStateLogs.$inferSelect;

import { deviceStockStatusLogs } from "./bean_device_stock_status_log";
export type IDeviceStockStatusLogInsert = Omit<typeof deviceStockStatusLogs.$inferInsert, BaseSchemaType>;
export type IDeviceStockStatusLogUpdate = IDeviceStockStatusLogInsert;
export type IDeviceStockStatusLogSelect = typeof deviceStockStatusLogs.$inferSelect;

import { authChallenges } from "./bean_auth_challenge";
export type IAuthChallengeInsert = Omit<typeof authChallenges.$inferInsert, BaseSchemaType>;
export type IAuthChallengeUpdate = IAuthChallengeInsert;
export type IAuthChallengeSelect = typeof authChallenges.$inferSelect;

import { protocols } from "./bean_protocol";
export type IProtocolInsert = Omit<typeof protocols.$inferInsert, BaseSchemaType>;
export type IProtocolUpdate = IProtocolInsert;
export type IProtocolSelect = typeof protocols.$inferSelect;

import { protocolInstances } from "./bean_protocol_instance";
export type IProtocolInstanceInsert = Omit<typeof protocolInstances.$inferInsert, BaseSchemaType>;
export type IProtocolInstanceUpdate = IProtocolInstanceInsert;
export type IProtocolInstanceSelect = typeof protocolInstances.$inferSelect;

import { protocolInstanceLogs } from "./bean_protocol_instance_log";
export type IProtocolInstanceLogInsert = Omit<typeof protocolInstanceLogs.$inferInsert, BaseSchemaType>;
export type IProtocolInstanceLogUpdate = IProtocolInstanceLogInsert;
export type IProtocolInstanceLogSelect = typeof protocolInstanceLogs.$inferSelect;

import { protocolInstanceReports } from "./bean_protocol_instance_report";
export type IProtocolInstanceReportInsert = Omit<typeof protocolInstanceReports.$inferInsert, BaseSchemaType>;
export type IProtocolInstanceReportUpdate = IProtocolInstanceReportInsert;
export type IProtocolInstanceReportSelect = typeof protocolInstanceReports.$inferSelect;

import { protocolInstanceMembers } from "./bean_protocol_instance_member";
export type IProtocolInstanceMemberInsert = Omit<typeof protocolInstanceMembers.$inferInsert, BaseSchemaType>;
export type IProtocolInstanceMemberUpdate = IProtocolInstanceMemberInsert;
export type IProtocolInstanceMemberSelect = typeof protocolInstanceMembers.$inferSelect;

import { protocolInstanceRunners } from "./bean_protocol_instance_runner";
export type IProtocolInstanceRunnerInsert = Omit<typeof protocolInstanceRunners.$inferInsert, BaseSchemaType>;
export type IProtocolInstanceRunnerUpdate = IProtocolInstanceRunnerInsert;
export type IProtocolInstanceRunnerSelect = typeof protocolInstanceRunners.$inferSelect;

import * as allTables from './beans_sqlite';
import { SQLiteTable } from "drizzle-orm/sqlite-core";

type TableTypes = {
  [K in keyof typeof allTables]: typeof allTables[K] extends SQLiteTable ? typeof allTables[K] : never
};

export type IAllTables = {
  [K in keyof TableTypes as typeof allTables[K] extends SQLiteTable ? K : never]: TableTypes[K]
};

export type IAllTablesSelect = {
  [K in keyof IAllTables]: IAllTables[K] extends { $inferSelect: any }
  ? IAllTables[K]['$inferSelect']
  : never
};

export type IAllTablesSelects = {
  [K in keyof IAllTables]: IAllTables[K] extends { $inferSelect: any }
  ? IAllTables[K]['$inferSelect'][]
  : never
};

export type IAllTablesInsert = {
  [K in keyof IAllTables]: IAllTables[K] extends { $inferInsert: any }
  ? IAllTables[K]['$inferInsert']
  : never
};

export const AllTables: IAllTables = Object.fromEntries(
  Object.entries(allTables).filter(
    ([_, value]) => value instanceof SQLiteTable
  )
) as IAllTables;

export const AllTablesColumns = Object.fromEntries(
  Object.entries(AllTables).map(([tableName, table]) => [
    tableName,
    (table as any)[Symbol.for('drizzle:Columns')]
  ])
)

export function removeSchemaBaseKeys<T extends object>(obj: T): Omit<T, BaseSchemaType> {
  const newObj = { ...obj };
  for (const key of schemaBaseKeys) {
    delete newObj[key as keyof T];
  }
  return newObj;
}