import { Protocol } from 'vigil-protocol';
import { InputTagSelect, TagOption } from '../../../../components/input_tag_select';

interface ProtocolsMembersProps {
  availableProtocols: Protocol[];
  selectedProtocols: Protocol[];
  onChange?: (protocols: Protocol[]) => void;
}

export const ProtocolsMembers: React.FC<ProtocolsMembersProps> = ({
  availableProtocols,
  selectedProtocols,
  onChange,
}) => {
  // Convert protocols to tag options
  const protocolOptions: TagOption[] = availableProtocols.map(protocol => ({
    label: protocol.name,
    value: protocol.uuid,
  }));

  const selectedTags: TagOption[] = selectedProtocols.map(protocol => ({
    label: protocol.name,
    value: protocol.uuid,
  }));

  const handleChange = (tags: TagOption[]): void => {
    // Convert selected tags back to Protocol objects
    const protocols = tags.map(tag => {
      const protocol = availableProtocols.find(f => f.uuid === tag.value);
      return protocol!;
    });
    onChange?.(protocols);
  };

  return (
    <div>
      <div className="font-bold text-sm">Protocols</div>
      <div className="italic text-sm">Select the protocols that should be linked to this protocol (order matters)</div>
      <InputTagSelect
        options={protocolOptions}
        selectedTags={selectedTags}
        onChange={handleChange}
        errors={[]}
        labelText=""
      />
    </div>
  );
}; 