import { ROUTES } from "../routes";
import { ScreenHome } from "../../screens/dashboard/home";
import { ScreenHomeOverview } from "../../screens/dashboard/home_overview";
import { ScreenHomeAssistent } from "../../screens/dashboard/home_assistent";
import { ScreenHomeSettings } from "../../screens/dashboard/home_settings";
import { ScreenHomeBilling } from "../../screens/dashboard/home_billing";
import { ScreenHomeInventory } from "../../screens/dashboard/home_inventory";
import { FullPageError } from "../../components/full_page_error";
import { FullPageSuspend } from "../../components/full_page_suspend";
import { RouteObject } from "react-router-dom";
import { ScreenHomeSites } from "../../screens/dashboard/home_sites";
import { ScreenHomeBeacons } from "../../screens/dashboard/home_beacons";
import { ScreenHomeBeacon } from "../../screens/dashboard/home_beacon";
import { ScreenHomeUsers } from "../../screens/dashboard/home_users";
import { ScreenHomeUser } from "../../screens/dashboard/home_user";
import { ScreenHomeDevice } from "../../screens/dashboard/home_device";
import { ScreenHomeRoles } from "../../screens/dashboard/home_roles";
import { ScreenHomeRole } from "../../screens/dashboard/home_role";
import { ScreenHomeOverviewMap } from "../../screens/dashboard/home_overview_map";
import { ScreenHomeNotifications } from "../../screens/dashboard/home_notifications";
import { ScreenHomeNotification } from "../../screens/dashboard/home_notification";
import { ScreenHomeDeviceLogsActivity } from "../../screens/dashboard/home_device_logs_activity";
import { ScreenHomeDeviceLogsState } from "../../screens/dashboard/home_device_logs_state";
import { ScreenHomeDeviceHistoryStatistics } from "../../screens/dashboard/home_device_history_statistics";
import { ScreenHomeDeviceHistoryLocation } from "../../screens/dashboard/home_device_history_location";
import { ScreenHomeOnboarding } from "../../screens/dashboard/home_onboarding";
import { ScreenHomeOnboardingNewOrganization } from "../../screens/dashboard/home_onboarding_new_organization";
import { ScreenHomeDevices } from "../../screens/dashboard/home_devices";
import { ScreenHomeProtocols } from "../../screens/dashboard/home_protocols";
import { ScreenHomeProtocol } from "../../screens/dashboard/home_protocol";
import { ScreenHomeProtocolOverview } from "../../screens/dashboard/home_protocol_overview";
import { ScreenHomeSite } from "../../screens/dashboard/home_site";
import { ScreenHomeUsersAccept } from "../../screens/dashboard/home_users_accept";
import { ScreenHomeUsersInvite } from "../../screens/dashboard/home_users_invite";
import { ScreenHomeProtocolInstances } from "../../screens/dashboard/home_protocol_instances";
import { ScreenHomeOverviewActiveInstances } from "../../screens/dashboard/home_overview_active_instances";
import { ScreenHomeProtocolInstance } from "../../screens/dashboard/home_protocol_instance";
import { ScreenHomeProtocolInstanceOverview } from "../../screens/dashboard/home_protocol_instance_overview";
import { ScreenHomeProtocolInstanceLogs } from "../../screens/dashboard/home_protocol_instance_logs";
import { ScreenHomeOverviewHistoricalInstances } from "../../screens/dashboard/home_overview_historical_instances";

export const RouterHome: RouteObject[] = [{
  path: ROUTES.ROUTE_HOME,
  errorElement: <FullPageError />,
  element: <FullPageSuspend><ScreenHome /></FullPageSuspend>,
  children: [
    {
      path: ROUTES.ROUTE_HOME_ONBOARDING,
      element: <ScreenHomeOnboarding />
    },
    {
      path: ROUTES.ROUTE_HOME_ONBOARDING_NEW_ORGANIZATION,
      element: <ScreenHomeOnboardingNewOrganization />
    },
    {
      path: ROUTES.ROUTE_HOME_OVERVIEW,
      element: <ScreenHomeOverview />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_OVERVIEW_MAP,
          element: <ScreenHomeOverviewMap />
        },
        {
          path: ROUTES.ROUTE_HOME_OVERVIEW_ACTIVE_INSTANCES,
          element: <ScreenHomeOverviewActiveInstances />
        },
        {
          path: ROUTES.ROUTE_HOME_OVERVIEW_HISTORICAL_INSTANCES,
          element: <ScreenHomeOverviewHistoricalInstances />
        }
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_ASSISTENT,
      element: <ScreenHomeAssistent />
    },
    {
      path: ROUTES.ROUTE_HOME_INVENTORY,
      element: <ScreenHomeInventory />
    },
    {
      path: ROUTES.ROUTE_HOME_BILLING,
      element: <ScreenHomeBilling />
    },
    {
      path: ROUTES.ROUTE_HOME_PROTOCOLS,
      element: <ScreenHomeProtocols />
    },
    {
      path: ROUTES.ROUTE_HOME_SETTINGS,
      element: <ScreenHomeSettings />
    },
    {
      path: ROUTES.ROUTE_HOME_ROLES,
      element: <ScreenHomeRoles />
    },
    {
      path: ROUTES.ROUTE_HOME_USERS,
      element: <ScreenHomeUsers />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_USERS_ACCEPT,
          element: <ScreenHomeUsersAccept />
        },
        {
          path: ROUTES.ROUTE_HOME_USERS_INVITE,
          element: <ScreenHomeUsersInvite />
        }
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_SITES,
      element: <ScreenHomeSites />
    },
    {
      path: ROUTES.ROUTE_HOME_BEACONS,
      element: <ScreenHomeBeacons />
    },
    {
      path: ROUTES.ROUTE_HOME_DEVICES,
      element: <ScreenHomeDevices />
    },
    {
      path: ROUTES.ROUTE_HOME_NOTIFICATIONS,
      element: <ScreenHomeNotifications />
    },
    {
      path: ROUTES.ROUTE_HOME_DEVICE,
      element: <ScreenHomeDevice />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_DEVICE_LOGS_ACTIVITY,
          element: <ScreenHomeDeviceLogsActivity />
        },
        {
          path: ROUTES.ROUTE_HOME_DEVICE_LOGS_STATE,
          element: <ScreenHomeDeviceLogsState />
        },
        {
          path: ROUTES.ROUTE_HOME_DEVICE_HISTORY_LOCATION,
          element: <ScreenHomeDeviceHistoryLocation />
        },
        {
          path: ROUTES.ROUTE_HOME_DEVICE_HISTORY_STATISTICS,
          element: <ScreenHomeDeviceHistoryStatistics />
        },
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_SITE,
      element: <ScreenHomeSite />,
    },
    {
      path: ROUTES.ROUTE_HOME_BEACON,
      element: <ScreenHomeBeacon />,
    },
    {
      path: ROUTES.ROUTE_HOME_PROTOCOL,
      element: <ScreenHomeProtocol />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_PROTOCOL_OVERVIEW,
          element: <ScreenHomeProtocolOverview />
        },
        {
          path: ROUTES.ROUTE_HOME_PROTOCOL_INSTANCES,
          element: <ScreenHomeProtocolInstances />
        },
        // {
        //   path: ROUTES.ROUTE_HOME_PROTOCOL_RAW_LOGS,
        //   element: <ScreenHomeProtocolRawLogs />
        // }
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_PROTOCOL_INSTANCE,
      element: <ScreenHomeProtocolInstance />,
      children: [
        {
          path: ROUTES.ROUTE_HOME_PROTOCOL_INSTANCE_OVERVIEW,
          element: <ScreenHomeProtocolInstanceOverview />
        },
        {
          path: ROUTES.ROUTE_HOME_PROTOCOL_INSTANCE_LOGS,
          element: <ScreenHomeProtocolInstanceLogs />
        }
      ]
    },
    {
      path: ROUTES.ROUTE_HOME_ROLE,
      element: <ScreenHomeRole />,
    },
    {
      path: ROUTES.ROUTE_HOME_USER,
      element: <ScreenHomeUser />,
    },
    {
      path: ROUTES.ROUTE_HOME_NOTIFICATION,
      element: <ScreenHomeNotification />
    },
  ]
}]
