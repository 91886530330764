import { lazy } from "react";
import { ROUTES } from "../routes";
import { FullPageError } from "../../components/full_page_error";
import { FullPageSuspend } from "../../components/full_page_suspend";
import { RouteObject } from "react-router-dom";

const ScreenPublicProtocolInstanceReport = lazy(() => import('../../screens/public/public_protocol_instance_report'));

export const RouterPublicProtocolInstanceReport: RouteObject[] = [{
  path: ROUTES.ROUTE_PUBLIC_PROTOCOL_INSTANCE_REPORT,
  errorElement: <FullPageError />,
  element: <FullPageSuspend><ScreenPublicProtocolInstanceReport /></FullPageSuspend>
}];