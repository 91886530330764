import { Permissions } from "vigil-datamodel";
import { TTActions, TTResource } from "tt-permissions";

class PermissionAttributes {
  name: string;
  resource: typeof TTResource;
  action: TTActions;
  selected?: boolean;

  constructor(name: string, resource: typeof TTResource, action: TTActions, selected?: boolean) {
    this.name = name;
    this.resource = resource;
    this.action = action;
    this.selected = selected;
  }
}

export class UIPermission {
  parentPermission: PermissionAttributes;
  childPermissions: PermissionAttributes[];

  constructor(parentPermission: PermissionAttributes, childPermissions: PermissionAttributes[]) {
    this.parentPermission = parentPermission;
    this.childPermissions = childPermissions;
  }
}

export const UiPermissions: UIPermission[] = [
  new UIPermission(
    new PermissionAttributes('Protocol Permissions', Permissions.RProtocol, TTActions.CRUD),
    [
      new PermissionAttributes('Create Protocols', Permissions.RProtocol, TTActions.C),
      new PermissionAttributes('View Protocols', Permissions.RProtocol, TTActions.R),
      new PermissionAttributes('Update Protocols', Permissions.RProtocol, TTActions.U),
      new PermissionAttributes('Delete Protocols', Permissions.RProtocol, TTActions.D),
    ]
  ),
  new UIPermission(
    new PermissionAttributes('Site Permissions', Permissions.RSite, TTActions.CRUD),
    [
      new PermissionAttributes('Create Sites', Permissions.RSite, TTActions.C),
      new PermissionAttributes('View Sites', Permissions.RSite, TTActions.R),
      new PermissionAttributes('Update Sites', Permissions.RSite, TTActions.U),
      new PermissionAttributes('Delete Sites', Permissions.RSite, TTActions.D),
    ]
  ),
  new UIPermission(
    new PermissionAttributes('Beacon Permissions', Permissions.RBeacon, TTActions.CRUD),
    [
      new PermissionAttributes('Create Beacons', Permissions.RBeacon, TTActions.C),
      new PermissionAttributes('View Beacons', Permissions.RBeacon, TTActions.R),
      new PermissionAttributes('Update Beacons', Permissions.RBeacon, TTActions.U),
      new PermissionAttributes('Delete Beacons', Permissions.RBeacon, TTActions.D),
    ]
  ),
  new UIPermission(
    new PermissionAttributes('Role Permissions', Permissions.RRole, TTActions.CRUD),
    [
      new PermissionAttributes('Create Roles', Permissions.RRole, TTActions.C),
      new PermissionAttributes('View Roles', Permissions.RRole, TTActions.R),
      new PermissionAttributes('Update Roles', Permissions.RRole, TTActions.U),
      new PermissionAttributes('Delete Roles', Permissions.RRole, TTActions.D),
    ]
  ),
  new UIPermission(
    new PermissionAttributes('User Permissions', Permissions.RUser, TTActions.CRUD),
    [
      new PermissionAttributes('Create User', Permissions.RUser, TTActions.C),
      new PermissionAttributes('View User', Permissions.RUser, TTActions.R),
      new PermissionAttributes('Update User', Permissions.RUser, TTActions.U),
      new PermissionAttributes('Delete User', Permissions.RUser, TTActions.D),
    ]
  ),
  new UIPermission(
    new PermissionAttributes('Device Permissions', Permissions.RDevice, TTActions.CRUD),
    [
      new PermissionAttributes('Create Device', Permissions.RDevice, TTActions.C),
      new PermissionAttributes('View Device', Permissions.RDevice, TTActions.R),
      new PermissionAttributes('Update Device', Permissions.RDevice, TTActions.U),
      new PermissionAttributes('Delete Device', Permissions.RDevice, TTActions.D),
    ]
  ),
  new UIPermission(
    new PermissionAttributes('User Invite Permissions', Permissions.RUserInvite, TTActions.CRUD),
    [
      new PermissionAttributes('Create User Invites', Permissions.RUserInvite, TTActions.C),
      new PermissionAttributes('View User Invites', Permissions.RUserInvite, TTActions.R),
      new PermissionAttributes('Update User Invites', Permissions.RUserInvite, TTActions.U),
      new PermissionAttributes('Delete User Invites', Permissions.RUserInvite, TTActions.D),
    ]
  ),
  new UIPermission(
    new PermissionAttributes('Beacon-Site Link Permissions', Permissions.RBeaconSiteLink, TTActions.CD),
    [
      new PermissionAttributes('Create Beacon-Site Link', Permissions.RUserInvite, TTActions.C),
      new PermissionAttributes('Delete Beacon-Site Link', Permissions.RUserInvite, TTActions.D),
    ]
  ),
  new UIPermission(
    new PermissionAttributes('User-Role Link Permissions', Permissions.RUserInvite, TTActions.CD),
    [
      new PermissionAttributes('Create User-Role Link', Permissions.RUserInvite, TTActions.C),
      new PermissionAttributes('Delete User-Role Link', Permissions.RUserInvite, TTActions.D),
    ]
  ),
];
