import parsePhoneNumber, { isValidPhoneNumber, getCountryCallingCode, CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';

type ValidationInput = string | number;

export function validate(input: ValidationInput, validators: any, returnCount = 1) {
  const result: any[] = [];
  for (const validator of validators) {
    const validatorResult = validator.execute(input);
    result.push(...validatorResult);
  }
  return result.slice(0, returnCount);
}

function mobile(countryCode?: CountryCode) {
  return {
    execute(input: ValidationInput) {
      const result = [];
      if (typeof input != "string") {
        throw Error('Attempting to validate mobile on a non string input.')
      }
      if (!input) {
        result.push('Please provide a valid mobile number');
        return result;
      }

      try {
        // If the input doesn't start with +, parse it with the country code
        let phoneNumber;
        if (input.startsWith('+')) {
          phoneNumber = parsePhoneNumber(input);
        } else if (countryCode) {
          phoneNumber = parsePhoneNumberFromString(input, countryCode);
        }

        // Check if the phone number is valid
        if (!phoneNumber || !phoneNumber.isValid()) {
          result.push('Please provide a valid mobile number');
          return result;
        }
      } catch (error) {
        result.push('Please provide a valid mobile number with country code');
      }
      
      return result;
    },
  };
}

function email() {
  return {
    execute(input: ValidationInput) {
      const result: string[] = [];

      if (typeof input != "string") {
        throw Error('Attempting to validate email on a non string input.')
      }
      
      if (input.length === 0) {
        result.push('Please provide an email');
        return result;
      }
      if (input.length < 3) {
        result.push('Please provide a valid email');
      }
      if (input.includes('.') === false) {
        result.push('Please provide a valid email');
      }
      
      // RFC 5322 compliant email regex
      const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      
      if (!emailRegex.test(input)) {
        result.push('Please provide a valid email');
      }
      
      return result;
    },
  };
}

function length(fieldName: any, min: any, max: any) {
  return {
    fieldName,
    min,
    max,
    execute(input: ValidationInput) {
      const result = [];
      if (typeof input != "string") {
        throw Error('Attempting to validate length on a non string input.')
      }
      if (!input) {
        result.push(
          `The ${this.fieldName} requires a value`,
        );
        return result;
      }
      if (input.length < this.min) {
        result.push(
          `The ${this.fieldName} requires at least ${this.min} characters`,
        );
      }
      if (input.length > max) {
        result.push(
          `The ${this.fieldName} requires no more than ${this.max} characters`,
        );
      }
      return result;
    },
  };
}

function password() {
  return {
    execute(input: ValidationInput) {
      const result = [];
      if (typeof input != "string") {
        throw Error('Attempting to validate password on a non string input.')
      }
      if (input.length === 0) {
        result.push('Please provide an password');
      }
      result.push(...length('password', 8, 100).execute(input));
      return result;
    },
  };
}

function required() {
  return {
    execute(input: any) {
      if (input === null || input === undefined || input === '') {
        return ['This field is required'];
      }
      return [];
    },
  };
}

function sameAs(fieldName: any, otherValue: any) {
  return {
    otherValue,
    execute(input: any) {
      const result: any[] = [];
      if (input !== otherValue) {
        return [`The ${fieldName} do not match`];
      }
      return result;
    },
  };
}

function cron() {
  const cronPattern = /^([\*\/\-\,0-9]+ ){4}[\*\/\-\,0-9]+$/;
  return {
    execute(input: ValidationInput) {
      const result: string[] = [];
      if (typeof input != "string") {
        throw Error('Attempting to validate cron expression on a non-string input.')
      }
      if (!cronPattern.test(input)) {
        result.push('Please provide a valid cron expression');
      }
      return result;
    },
  };
}

function numberBetween(fieldName: string, min: number, max: number) {
  return {
    fieldName,
    min,
    max,
    execute(input: number) {
      const result: string[] = [];

      if (typeof input !== "number") {
        throw Error(`Attempting to validate ${this.fieldName} with a non-number input.`);
      }

      if (input < this.min || input > this.max) {
        result.push(`Please provide a valid ${this.fieldName} between ${this.min} and ${this.max}.`);
      }

      return result;
    },
  };
}

// function min(){

// }
// function max(){

// }
// function range() {

// }

export const VALIDATORS = {
  mobile,
  email,
  password,
  length,
  required,
  sameAs,
  cron,
  numberBetween,
};
