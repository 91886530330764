import { VigilMapProps } from "./vigil_map_types";

export const VIGIL_MAP_MSG_NAME = 'message';

export enum VigilMapMessageId {
  SET_STATE = 'SET_STATE',
  FIT_BOUNDS = 'FIT_BOUNDS',
  CENTER = 'CENTER',
  ZOOM = 'ZOOM',
  FLY_TO = 'FLY_TO',
  LOADED = 'LOADED' // Sent from the map to the container when loaded
}

export type VigilMapMessageBase = {
  id: VigilMapMessageId;
}

export type VigilMapMessageSetState = VigilMapMessageBase & {
  id: VigilMapMessageId.SET_STATE;
  state: VigilMapProps;
}

export type VigilMapMessageFitBounds = VigilMapMessageBase & {
  id: VigilMapMessageId.FIT_BOUNDS;
  bounds: maplibregl.LngLatBoundsLike;
  options?: maplibregl.FitBoundsOptions;
}

export type VigilMapMessageCenter = VigilMapMessageBase & {
  id: VigilMapMessageId.CENTER;
  location: maplibregl.LngLatLike;
}

export type VigilMapMessageZoom = VigilMapMessageBase & {
  id: VigilMapMessageId.ZOOM;
  zoom: number;
}

export type VigilMapMessageFlyTo = VigilMapMessageBase & {
  id: VigilMapMessageId.FLY_TO;
  options: maplibregl.FlyToOptions;
}
export type VigilMapMessageLoaded = VigilMapMessageBase & {
  id: VigilMapMessageId.LOADED;
}

// TODO: Expand this list to match most or all of maplibre's functions

export type VigilMapMessage = VigilMapMessageSetState | VigilMapMessageFitBounds | VigilMapMessageCenter | VigilMapMessageZoom | VigilMapMessageFlyTo | VigilMapMessageLoaded;

export function sendVigilMapMessage(msg: VigilMapMessage) {
  window.postMessage(JSON.stringify(msg));
}

export function sendVigilMapMessageToParent(msg: VigilMapMessage) {
  if ((window as any).ReactNativeWebView) {
    (window as any).ReactNativeWebView.postMessage(JSON.stringify(msg));
  } else {
    parent.postMessage(JSON.stringify(msg), '*');
  }
}
