import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { protocolInstances } from "./bean_protocol_instance";
import { relations } from "drizzle-orm";

export enum ProtocolInstanceMemberType {
  SERVER = 'SERVER',
  PROTOCOL = 'PROTOCOL',
  DEVICE = 'DEVICE',
  USER = 'USER',
  SITE = 'SITE',
  BEACON = 'BEACON',
}

export const protocolInstanceMembers = sqliteTable('protocol_instance_members', {
  ...schemaBase(),
  uuidProtocolInstance: text('uuid_protocol_instance').notNull().references(() => protocolInstances.uuid),
  uuidMember: text('uuid_member').notNull(),
  type: text('type', { enum: Object.values(ProtocolInstanceMemberType) as [ProtocolInstanceMemberType] }).notNull(),
}, (table) => ({
  protocolInstanceMemberIdx: uniqueIndex('protocol_instance_member_idx').on(table.uuidProtocolInstance, table.uuidMember)
}));

export const protocolInstanceMembersRelations = relations(protocolInstanceMembers, ({ one }) => ({
  protocolInstance: one(protocolInstances, {
    fields: [protocolInstanceMembers.uuidProtocolInstance],
    references: [protocolInstances.uuid],
  }),
}));