import { useContext, useEffect, useState } from "react";
import { Modal, ModalProps } from "../../../components/modal";
import { IOrganizationRoleSelect, IOrganizationRoleUserInviteInsert, Permissions } from "vigil-datamodel";
import { UserInviteCreateEmail } from "./user_invite_create_email";
import { UserInviteCreateMobile } from "./user_invite_create_mobile";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { ContextRoles } from "../../../providers/provider_roles";
import { StatusAlert } from "../../../components/status_alert";
import { UserInviteCreateQr } from "./user_invite_create_qr";
import { Tabs } from "../../../components/tabs";
import { IconEnvelope, IconInboxStack, IconQrCodeSolid } from "../../../components/icons";

interface ModalUserInviteCreateProps extends ModalProps {
  defaultRole?: IOrganizationRoleSelect;
  onSubmit?: (inviteInfo: IOrganizationRoleUserInviteInsert) => Promise<void>;
}

export const ModalUserInviteCreate: React.FC<ModalUserInviteCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const roles = useContext(ContextRoles);

  const [stateError, setError] = useState<string>('');
  const [stateActiveTabId, setActiveTabId] = useState<'email' | 'mobile' | 'qr'>('email');
  const [stateOrganizationRoles, setOrganizationRoles] = useState<IOrganizationRoleSelect[]>([]);
  const [stateLowestRole, setLowestRole] = useState<IOrganizationRoleSelect>(null as any);

  useEffect(() => {
    fetchOrganizationRoles();
    setLowestRole(Permissions.getLowestRole(roles.organizationRoles));
  }, [roles.organizationRoles]);

  async function fetchOrganizationRoles() {
    if (!organization.data) return;
    try {
      setError('');
      const roles = await vigil.functions.findManyRoles({ uuidOrganization: organization.data.uuid });
      setOrganizationRoles(roles);
    } catch (error: any) {
      setError(error.message);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-96">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Invite User</h3>
      
      <Tabs selectedId={stateActiveTabId} onSelect={(id) => setActiveTabId(id as 'email' | 'mobile' | 'qr')} fillWidth={true} items={[
        {
          icon: IconInboxStack,
          id: 'email',
          text: 'Email'
        },
        {
          icon: IconEnvelope,
          id: 'mobile', 
          text: 'Mobile'
        }
      ]} />

      {stateError && <StatusAlert type="alert-error" message={stateError} />}
      {stateActiveTabId == 'email' && <UserInviteCreateEmail organizationRoles={stateOrganizationRoles} lowestRole={props.defaultRole ?? stateLowestRole} {...props} />}
      {stateActiveTabId == 'mobile' && <UserInviteCreateMobile organizationRoles={stateOrganizationRoles} lowestRole={props.defaultRole ?? stateLowestRole} {...props} />}
      {stateActiveTabId == 'qr' && <UserInviteCreateQr organizationRoles={stateOrganizationRoles} lowestRole={props.defaultRole ?? stateLowestRole} {...props} />}
    </Modal>
  )
}
